import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/metadata.min.json';
import sortBy from 'lodash/sortBy';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ROLES_PRIORATES, TRANSLATE_LANGUAGES } from 'helpers/enum.helpers';

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
export const sortByKeys = (object) => {
  const keys = Object.keys(object);
  const sortedKeys = reverse(sortBy(keys));

  return fromPairs(map(sortedKeys, (key) => [key, object[key]]));
};

export function isValidPhoneNumber(value) {
  if (!value) return false;

  const phoneNumber = parsePhoneNumberFromString(value, metadata);
  if (!phoneNumber) return false;

  return phoneNumber.isValid();
}

export function isRole(roleArray, userObj) {
  if (!userObj) return false;
  let role = userObj.role;
  if (role === 'master' && userObj.substitute_admin) {
    role = 'administrator';
  }
  // const isMarketing = roleArray.find(el => el === "jivo") && userObj.marketing_tag
  if (!userObj.role) throw new Error('Пользователь не имеет пропа роли.');
  // return roleArray.includes(userObj.role) || isMarketing

  return roleArray.includes(role);
}

export function isRoleGreat(currentRole, contrastRole) {
  if (!currentRole || !contrastRole) return false;
  return ROLES_PRIORATES[currentRole] < ROLES_PRIORATES[contrastRole] ? true : false;
}

export function isRoleStore(roleString, userObj) {
  if (!userObj || !userObj.role || !userObj.stores) {
    return false;
  }

  if (!Array.isArray(roleString)) {
    roleString = [roleString];
  }

  return roleString.some((role) => userObj.stores[role]);
}

export function isStore(storeId) {
  try {
    return JSON.parse(localStorage.getItem('user')).user.stores?.store.find(
      (el) => parseInt(el) === parseInt(storeId),
    );
  } catch {
    return false;
  }
}

export function getCertificateValidity(certificate) {
  if (certificate.used) {
    return (
      <span style={{ color: 'gray' }}>Использован (Сеанс №{certificate.used.session_id})</span>
    );
  } else {
    const now = moment(),
      validity = moment(certificate.validity, 'YYYY-MM-DD');
    if (validity < now) {
      return (
        <span style={{ color: 'red' }}>
          Просрочен (до {moment(certificate.validity).format('DD.MM.YYYY')})
        </span>
      );
    }
    return (
      <span className={'color-200'}>
        Годен (до {moment(certificate.validity).format('DD.MM.YYYY')})
      </span>
    );
  }
}

export function joinUserNames(userObj, pure = true) {
  if (!userObj) return 'Not defined';
  const role = userObj.role ? userObj.role : null;

  const weekend = userObj.weekends?.length
    ? `(Выходной в этот день с ${userObj.weekends[0].from_time} – по ${userObj.weekends[0].to_time})`
    : '';

  const has_marketing_tag = userObj.marketing_tag && userObj.marketing_tag;
  const tag = has_marketing_tag ? `(${userObj.marketing_tag.name})` : '';

  const fullName =
    [userObj.first_name, userObj.middle_name, userObj.last_name].join(' ').trim() || 'Not defined';
  if (!pure) {
    return (
      <span className={role}>
        {fullName} {tag}
      </span>
    );
  }
  return `${fullName}${weekend} ${tag}`;
}

export function mapParlors(parlors) {
  if (parlors.length) {
    return parlors.map((el) => el.parlor.name).join(', ');
  } else {
    return '* No parlors *';
  }
}

export function mapSessionTypes(types) {
  if (types.length) {
    return types.join(', ');
  } else {
    return '* No types *';
  }
}

export function canManageSession(sessionParlor, user, parlor) {
  const equalParlors = parseInt(parlor) === parseInt(sessionParlor);
  return (
    (equalParlors && isRole(['administrator', 'director'], user)) ||
    isRole(['owner', 'office'], user)
  );
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const CanvaBuilt = ({ code }) => {
  const url = `https://www.canva.com/design/${code}/view?embed`;
  return window.location.href.startsWith('https://crm.ink-side-tattoo.com/') ? null : (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '0',
        paddingTop: '56.2500%',
        paddingBottom: '0',
        boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
        'margin-top': '1.6em',
        'margin-bottom': '0.9em',
        overflow: 'hidden',
        'border-radius': '8px',
        willChange: 'transform',
      }}
    >
      <iframe
        loading='lazy'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0',
          border: 'none',
          padding: '0',
          margin: '0',
        }}
        src={url}
        allowFullScreen='allowFullScreen'
        allow='fullscreen'
      ></iframe>
    </div>
  );
};

export const convertObjectToBase64 = (obj) => {
  return Buffer.from(JSON.stringify(obj)).toString('base64');
};

export const toDataUrl = (url, callback) => {
  var xhr = new XMLHttpRequest();

  xhr.onload = function () {
    var reader = new FileReader();

    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };

  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export const getMultiRowsHeader = (string) => {
  const words = string.split(' ');
  const arr = words.reduce((acc, word) => {
    const lastElement = acc[acc.length - 1];
    if (lastElement && lastElement.split(' ').length < 3) {
      acc[acc.length - 1] += ' ' + word;
    } else {
      acc.push(word);
    }
    return acc;
  }, []);
  return arr.map((str) => <p>{str}</p>);
};

export const getQRCode = (text) => {
  return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${text}`;
};
export const mapChooser = (chooser, value) => {
  if (!chooser) return;
  const foundItem = chooser.find((f) => f.value === value);
  return foundItem?.label || foundItem?.name;
};

export const base64ToBlob = (base64, type) => {
  const binary = atob(base64.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type });
};

export const generateNameWallet = (
  wallet,
  balance,
  balanceEnabled,
  amountMin,
  amountMax,
  recommended,
  forDisplay = true,
) => {
  const parts = [wallet];

  if (amountMin) parts.push(`min:${amountMin}`);
  if (amountMax) parts.push(`max:${amountMax}`);

  const nameWithAmounts = parts.join(' ');

  if (forDisplay) {
    if (balanceEnabled) {
      return (
        <>
          {recommended && <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />}{' '}
          {nameWithAmounts} ({balance})
        </>
      );
    }

    return (
      <>
        {recommended && <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />}{' '}
        {nameWithAmounts}
      </>
    );
  } else {
    const star = recommended ? '★ ' : '';
    return balanceEnabled ? `${star}${nameWithAmounts} (${balance})` : `${star}${nameWithAmounts}`;
  }
};

export const getPublicationFields = (fieldName, publication) => {
  return TRANSLATE_LANGUAGES.reduce((acc, lang) => {
    acc[lang.value] = publication?.[`${fieldName}_${lang.value}`];
    return acc;
  }, {});
};

export const getTranslatedField = (object, field_name) => {
  return object ? object[field_name] || object[field_name + '_default'] : null;
};
