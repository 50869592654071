import { handleAxiosErrorSwal } from 'helpers';

export const statusModerateValue = {
  new: 'Новый',
  in_waiting: 'В очереди',
  cancel: 'Отклонено',
  in_process: 'В процессе',
  requires_clarification: 'Требует уточнения',
  approved: 'Завершено',
  filled_in: 'Залито',
};

export const statusModerateOptions = [
  {
    value: 'new',
    label: 'Новый',
  },
  {
    value: 'in_waiting',
    label: 'В очереди',
  },
  {
    value: 'cancel',
    label: 'Отклонено',
  },
  {
    value: 'in_process',
    label: 'В процессе',
  },
  {
    value: 'requires_clarification',
    label: 'Требует уточнения',
  },
  {
    value: 'approved',
    label: 'Завершено',
  },
  { value: 'filled_in', label: 'Залито' },
];

export const statusModerateColor = {
  new: 'rgb(135, 206, 250, 1)',
  in_waiting: 'rgb(255, 165, 0, 1)',
  cancel: 'rgb(253, 93, 147, 1)',
  in_process: 'rgb(30, 144, 255, 1)',
  requires_clarification: 'rgb(255, 215, 0, 1)',
  approved: 'rgb(146, 199, 19, 1)',
  filled_in: 'rgb(147, 112, 219, 1)',
};

export const categoryTicketValue = {
  crm: 'CRM',
  customer: 'Customer',
  site: 'Сайт vean-tattoo',
  shop: 'Магазин',
  mobile_app: 'Мобильное приложение',
};

export const categorySupportTicketValue = {
  general_question: 'Общий вопрос',
  quality_control: 'Контроль качества',
  shop: 'Магазин',
  hr: 'HR',
  promo: 'Реклама (Digital, PR, SMM)',
  student: 'Обучения',
  sales: 'Продажи',
  security: 'Служба безопасности',
  audit: 'Аудит',
  crm: 'CRM/Сайт',
};

export const categoryTicketGeneralValue = { ...categoryTicketValue, ...categorySupportTicketValue };

export const categorySupportTicketOptions = [
  {
    value: 'general_question',
    label: 'Общий вопрос',
  },
  {
    value: 'quality_control',
    label: 'Контроль качества',
  },
  {
    value: 'shop',
    label: 'Магазин',
  },
  {
    value: 'hr',
    label: 'HR',
  },
  {
    value: 'promo',
    label: 'Реклама (Digital, PR, SMM)',
  },
  {
    value: 'student',
    label: 'Обучения',
  },
  {
    value: 'sales',
    label: 'Продажи',
  },
  {
    value: 'security',
    label: 'Служба безопасности',
  },
  {
    value: 'audit',
    label: 'Аудит',
  },
  {
    value: 'crm',
    label: 'CRM/Сайт',
  },
];

export const categoryTicketOptions = [
  {
    value: 'crm',
    label: 'CRM',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'site',
    label: 'Сайт vean-tattoo',
  },
  {
    value: 'shop',
    label: 'Магазин',
  },
  {
    value: 'mobile_app',
    label: 'Мобильное приложение',
  },
];

export const isBlockFunctional = !['cr.vean-tattoo.pl', 'localhost'].includes(
  window.location.hostname,
);

export const getIsModeratorTicket = ({ api_v2, setIsModeratorTicket }) => {
  if (import.meta.env.DEV) {
    return setIsModeratorTicket(true);
  } else {
    const fetchIsModeratorTicket = () => {
      api_v2
        .get('/ticket-moderator/')
        .then((response) => setIsModeratorTicket(response.data.is_moderator))
        .catch(handleAxiosErrorSwal);
    };
    fetchIsModeratorTicket();
  }
};
