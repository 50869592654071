import { useApolloClient, useQuery, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import lodash from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
} from 'react';
import {
  fetchSiteParlorPage,
  fetchAppsSesionsSessiotypeTranslation,
  fetchAdvertTypesQuery,
  fetchAdvertKindTypesQuery,
  fetchParlorsQuery,
  fetchEmployeesQuery,
  fetchEmployeesQueryByRole,
  fetchEmployeesQueryFromShop,
  fetchStudentQuery,
  fetchExpenseTypesQuery,
  fetchFilteredEmployeesQuery,
  SKILL_BOX_MODERATION_CONT,
  fetchTypeStudentPayments,
  DATE_COOPERATION,
  fetchStudentsSearch,
  fetchCouchPrice,
  fetchFindOutOptionsQuery,
  fetchSessionTypesQuery,
  fineTypesQuery,
  fetchUseWorkTax,
  fetchParlorItemsQuery,
  fetchCategoriesQueryAvailable,
  fetchStoreCategories,
  fetchItemsQueryAvailable,
  fetchCategoriesQuery,
  fetchParlorStoreItemsQuery,
  fetchParlorStoreItemVariantsQuery,
  fetchCategoriesAddQuery,
  fetchParlorStoreItemsAddQuery,
  fetchItemsQueryAvailableCustomer,
  fetchCollectionAttributesQuery,
  fetchAttributesQuery,
  fetchCandidatesCoverse,
  fetchSessionSize,
  TYPEEDUCATION,
  CUSTOMER_FORM_STATUS,
  SITE_FORM_NAMES,
  SITE_FORM_RESPONSIBLES,
  STYLE,
  fetchCustomersCountQuery,
  fetchUsernameByUser,
  fetchPhotoDiplom,
  fetchSessionTypeSubcategories,
  fetchGadgetsByParlor,
  fetchAttendanceTheme,
  fetchDirectorByParlor,
  fetchNeedStyleCount,
  DISPUTE_COUNT,
  OPEN_DISPUTE_COUNT,
  CHECK_LIST_INFO_COUNT,
  STUDENT_CLOSING_MODER_COUNT,
  USER_TASKS_COUNT,
  NEWS_COUNT,
  WITHDRAW_SALARY_COUNT,
  NEED_PAYMENT_CATEGORY,
  SESSIONS_FORM,
  ACTIVE_SESSIONS,
  GADGET_NAME,
  TYPEPROOFS,
  CATEGORY_INCOMING_PAYEMNT,
} from 'services/gql/index.jsx';
import { joinUserNames, isRole, selectStyles, handleAxiosErrorSwal } from 'src/helpers';
import { useTranslation } from 'react-i18next';

import { ParlorContext, UserContext } from 'src/context.jsx';
import { FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { getLastMonthSell } from '../views/Store/StoreTable';
import {
  FETCH_PARLOR_ITEMS,
  FETCH_PARLOR_ITEMS_DATA,
  FETCH_PARLOR_STORE,
} from '../views/Store/Querries/goodQueries.gql';
import { fetchOrderItemsDeletedQuery, fetchParlorMarketableItemsQuery } from '../services/gql';
import moment from 'moment';
import i18next from 'i18next';
import { getCountryCode } from 'helpers/getCountryCode';
import Select from 'react-select';
import { isInkSide } from '../helpers';
import { CurrencyContext } from '../context';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  return windowSize;
}

export const useMonths = () => {
  const { t } = useTranslation();
  const months = [
    { label: t('months.january'), value: 1 },
    { label: t('months.february'), value: 2 },
    { label: t('months.march'), value: 3 },
    { label: t('months.april'), value: 4 },
    { label: t('months.may'), value: 5 },
    { label: t('months.june'), value: 6 },
    { label: t('months.july'), value: 7 },
    { label: t('months.august'), value: 8 },
    { label: t('months.september'), value: 9 },
    { label: t('months.october'), value: 10 },
    { label: t('months.november'), value: 11 },
    { label: t('months.december'), value: 12 },
  ];
  return { months };
};

export const useSearchState = (name, defaultValue) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const setState = useCallback(
    (value) => {
      if (value) searchParams.set(name, value);
      else searchParams.delete(name);
      navigate({
        pathname,
        search: `?${searchParams.toString()}`,
      });
    },
    [name, pathname, searchParams, navigate],
  );
  return [searchParams.get(name) ?? defaultValue, setState];
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useStorePermission = (store) => {
  const { storesData } = useContext(UserContext);
  if (Array.isArray(storesData)) {
    return storesData.filter((el) => el.value === store).length > 0;
  }
  return false;
};

export const useToggle = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const toggleState = useCallback(() => setState((prev) => !prev), []);
  return [state, toggleState];
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.languages[i18n.languages.length - 1];
};

export const useExpenseTypes = (relatedTo = null) => {
  const [types, setTypes] = useState([]);
  const client = useApolloClient();

  useEffect(() => {
    client
      .query({
        query: fetchExpenseTypesQuery,
        variables: { related_to: relatedTo },
      })
      .then(({ data }) => {
        setTypes(
          data.expenses_expensetype.map((el) => ({
            value: el.id,
            label: el.name,
          })),
        );
      })
      .catch(console.log);
  }, [client]);
  return types;
};

export const useIncomingPaymentsCategories = (useApiV2, collectionsOnly = false) => {
  const [categories, setCategorie] = useState([]);
  const [{ data }, execute] = useApiV2(
    {
      method: 'GET',
      url: `/expenses/incoming-categories/`,
      params: collectionsOnly ? { collections: true } : { collections: '' },
    },
    { manual: true },
  );
  useEffect(() => {
    if (data) {
      setCategorie((data || []).map((el) => ({ label: el.name, value: el.id })));
    }
  }, [data]);
  return { execute, categories };
};

export const useParlorAddStore = (parlor) => {
  const [loadCategories, { data, loading, error, called }] = useLazyQuery(fetchCategoriesAddQuery, {
    variables: { parlor },
  });
  return useMemo(() => {
    if (loading || error || !called) return [loadCategories, []];
    const cats = data.category
      .filter((el) => !el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
      }));
    const subCategories = data.category
      .filter((el) => el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
        parent: el.parent_id,
      }));
    return [
      loadCategories,
      [{ label: 'Все категории', value: null }, ...cats],
      [{ label: 'Все товары', value: null }, ...subCategories],
    ];
  }, [data, loading, error, called, loadCategories]);
};

export const useParlorStoreItemsPagination = (
  setPage,
  activeTab,
  parlor,
  categories,
  offset,
  limit,
  sorted,
  payHalfPointsFilter,
  payFullPointsFilter,
  categoriesFilter,
  commentsFilter,
  search,
  fullSearchFilter,
  sellsFilter,
  propositionsFilter,
  officialFilter,
  unOfficialFilter,
) => {
  const lang = useLanguage();
  const { user } = useContext(UserContext);
  const [loadItemsSimple, { data }] = useLazyQuery(
    FETCH_PARLOR_ITEMS(
      activeTab,
      fullSearchFilter,
      payHalfPointsFilter,
      payFullPointsFilter,
      categoriesFilter,
      commentsFilter,
      propositionsFilter,
      sorted,
      officialFilter,
      unOfficialFilter,
    ),
  );

  useEffect(() => {
    setPage(0);
    loadItemsSimple({
      variables: {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        parlorId: parlor,
        ...(payHalfPointsFilter ? { half_points: true } : {}),
        ...(payFullPointsFilter ? { full_points: true } : {}),
        ...(categoriesFilter?.length ? { categories: categoriesFilter } : {}),
      },
    });
  }, [
    loadItemsSimple,
    parlor,
    payHalfPointsFilter,
    payFullPointsFilter,
    categoriesFilter,
    commentsFilter,
    fullSearchFilter,
    propositionsFilter,
    setPage,
    sorted,
    officialFilter,
    unOfficialFilter,
  ]);

  const itemsFiltered = useMemo(() => {
    const dataClone = lodash.cloneDeep(data);

    const searchFilter = (item) => {
      if (item.item.variant?.name === 'main') {
        return data.store.filter(
          (el) =>
            el.item.parent === item.item.id &&
            `${el.item.name} ${el.item.variant?.name ?? ''}`
              .toLowerCase()
              .includes(search.toLowerCase()),
        ).length;
      } else {
        return `${item.item.name} ${item.item.variant?.name ?? ''}`
          .toLowerCase()
          .includes(search.toLowerCase());
      }
    };
    return dataClone?.store
      .filter((item) => {
        return !fullSearchFilter
          ? isRole('administrator', user) //add this to attributes filter
            ? data.store.filter((el) => el.value !== 0 && item.item.id === el.item.parent).length ||
              item.value !== 0
            : data.store.filter((el) => el.value !== 0 && item.item.id === el.item.parent).length ||
              item.value !== 0
          : true;
      })
      .filter((el) => (search ? searchFilter(el) : true))
      .filter((item) => (sellsFilter ? getLastMonthSell(item.item.marketable).length > 0 : true))
      .sort((a, b) => {
        return !sorted
          ? b.item.marketable?.aggregate.count - a.item.marketable?.aggregate.count
          : 0;
      });
  }, [data, payHalfPointsFilter, payFullPointsFilter, sellsFilter, search, sorted]);

  const itemsByPage = useMemo(() => {
    if (itemsFiltered?.length) {
      const noChildrenItems = itemsFiltered
        .filter((el) => !el.item.parent)
        .slice(offset, offset + limit);
      const childrenItems = itemsFiltered.filter((el) =>
        noChildrenItems.find((item) => item.item.id === el.item.parent),
      );
      return noChildrenItems.concat(childrenItems);
    }
    return [];
  }, [itemsFiltered, offset, limit, sorted]);

  const {
    data: fullData,
    loading: loadingFullData,
    error: errorFullData,
    refetch,
  } = useQuery(FETCH_PARLOR_ITEMS_DATA(sorted), {
    variables: {
      ids: itemsByPage.map((el) => el.id),
      parlorId: parlor,
      from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    },
    fetchPolicy: 'cache-and-network',
    skip: !itemsByPage.map((el) => el.id).length,
  });

  const currencies = useContext(CurrencyContext);
  const defaultCurrency = currencies?.currencies?.find((currency) => currency.value === 1);

  const getPriceUnits = (price, item) => {
    if (item.item_units?.length) {
      return price / item.item_units[0].unit.value;
    }
    return price;
  };

  const items = useMemo(() => {
    if (loadingFullData || errorFullData) {
      return [];
    }
    const items = fullData?.store.map((el) => ({
      ...el,
      item: {
        ...el.item,
        provided_price: getPriceUnits(el.item.provided_price, el.item),
        shop_price: getPriceUnits(el.item.shop_price, el.item),
        purchase_price: getPriceUnits(el.item.purchase_price, el.item),
        sell_price_extra: calculateSellPriceExtra(
          el,
          el.item.item_units,
          'sell_price',
          defaultCurrency,
        ),

        sell_price: calculateSellPrice(el, el.item.item_units),
        category: !el.item.category.parent_id
          ? {
              label: el.item.category.name,
              value: el.item.category.id,
            }
          : categories.find((cat) =>
              cat.value === el.item.category.parent_id ? { label: cat.name, value: cat.id } : false,
            ),
        sub_category: el.item.category.parent_id
          ? {
              label: el.item.category.name,
              value: el.item.category.id,
            }
          : { label: 'Все товары', value: null },
      },
    }));
    return items;
  }, [fullData, categories, lang, loadingFullData, errorFullData]);

  return {
    items,
    total: itemsFiltered?.filter((el) => !el.item.parent)?.length,
    loading: loadingFullData,
    error: errorFullData,
    refetch,
    itemsTotal: itemsFiltered,
  };
};

export const useParlorStore = (fullSearchFilter, parlorId, categories) => {
  const lang = useLanguage();
  const { data, loading, error, refetch } = useQuery(FETCH_PARLOR_STORE(fullSearchFilter), {
    variables: { parlorId },
    fetchPolicy: 'cache-and-network',
  });
  const getPriceUnits = (price, item) => {
    if (item.item_units.length) {
      return price / item.units[0].unit.value;
    }
    return price;
  };

  const items = useMemo(() => {
    if (loading || error) return [];

    const items = data.store.map((el) => ({
      ...el,
      item: {
        ...el.item,
        provided_price: getPriceUnits(el.item.provided_price, el.item),
        shop_price: getPriceUnits(el.item.shop_price, el.item),
        purchase_price: getPriceUnits(el.item.purchase_price, el.item),
        sell_price: getPriceUnits(el.item.sell_price, el.item),
        category: !el.item.category.parent_id
          ? {
              label: el.item.category.name,
              value: el.item.category.id,
            }
          : categories.find((cat) =>
              cat.value === el.item.category.parent_id ? { label: cat.name, value: cat.id } : false,
            ),
        sub_category: el.item.category.parent_id
          ? {
              label: el.item.category.name,
              value: el.item.category.id,
            }
          : { label: 'Все товары', value: null },
      },
    }));
    return items;
  }, [data, lang, loading, error, categories]);

  return { items, loading, error, refetch };
};

export const useCandidateConversionURL = () => {
  const { data, loading } = useQuery(fetchCandidatesCoverse, {
    fetchPolicy: 'cache-and-network',
  });

  const conversionsURLOptions = useMemo(() => {
    return (
      data?.candidates?.map((el) => ({
        label: el.link,
        value: el.link,
      })) || []
    );
  }, [data]);

  return {
    conversionsURLOptions: [
      ...conversionsURLOptions,
      {
        label: 'Нет',
        value: null,
      },
    ],
    data,
    loading,
  };
};

export const useParlorStoreCategories = (parlor) => {
  const lang = useLanguage();
  const { data, loading, error } = useQuery(fetchCategoriesQuery, {
    variables: { parlor },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    if (loading || error) return [[], []];
    const cats = data.category
      .filter((el) => !el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
      }));
    const subCategories = data.category
      .filter((el) => el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
        parent: el.parent_id,
      }));
    return {
      categories: [{ label: 'Все категории', value: null }, ...cats],
      subCategories: [{ label: 'Все товары', value: null }, ...subCategories],
    };
  }, [data, lang, loading, error]);
};

export const useParlorStoreCategoriesMenu = (parlor) => {
  const lang = useLanguage();
  const { data, loading, error } = useQuery(fetchCategoriesQuery, {
    variables: { parlor: [parlor] },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    if (loading || error) return [[], []];
    const cats = data.category
      .filter((el) => !el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
        children: data.category
          .filter((a) => a.parent_id == el.id)
          .map((a) => ({
            label: a.name,
            value: a.id,
            parent: a.parent_id,
          })),
      }));
    return {
      categories: [{ label: 'Все категории', value: null }, ...cats],
    };
  }, [data, lang, loading, error]);
};

export const useWarehouseStoreCategoriesMenu = (store) => {
  const lang = useLanguage();
  const { data, loading, error } = useQuery(fetchStoreCategories, {
    variables: { store },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    if (loading || error) return [[], []];
    const cats = data.category
      .filter((el) => !el.parent_id)
      .map((el) => ({
        label: el.name,
        value: el.id,
        children: data.category
          .filter((a) => a.parent_id == el.id)
          .map((a) => ({
            label: a.name,
            value: a.id,
            parent: a.parent_id,
          })),
      }));
    return {
      categories: [{ label: 'Все категории', value: null }, ...cats],
    };
  }, [data, lang, loading, error]);
};

export const transformItemsData = (data, categories, lang, defaultCurrency) => {
  if (!data || !data.parlorstore) {
    return [];
  }

  const transformedData = data.parlorstore.map((parlor_item) =>
    transformParlorItem(parlor_item, categories, data, defaultCurrency),
  );

  return sortDataByLastMonthSales(transformedData);
};

const getCategory = (parlor_item, parentCategory) =>
  parlor_item.item.category.parent ? parentCategory : parlor_item.item.category.value;

const getSubCategory = (parlor_item) =>
  parlor_item.item.category.parent ? parlor_item.item.category.value : null;

const calculateSellPrice = (parlor_item, units) => {
  return units?.length
    ? parlor_item.item.sell_price / units[0].unit.value
    : parlor_item.item.sell_price;
};
const calculateSellPriceExtra = (parlor_item, units, price_type, defaultCurrency) => {
  const roundedPrice = (price) => {
    if (price < 1) {
      return lodash.round(price, 2);
    }

    if (!defaultCurrency) return lodash.round(price);

    if (['pln', 'eur', 'usd', 'eur', 'czk', 'uah', 'bgn', 'gbp'].includes(defaultCurrency.label)) {
      return Math.round(price);
    } else if (defaultCurrency.label === 'huf') {
      return Math.round(price / 100) * 100;
    } else {
      return lodash.round(price);
    }
  };

  if (isInkSide() || parlor_item.pricecategory.percentage == 0) {
    return units?.length
      ? parlor_item.item[price_type] / units[0].unit.value
      : parlor_item.item[price_type];
  }

  return units?.length
    ? lodash.round(
        (parlor_item.item[price_type] * (1 + parlor_item.pricecategory.percentage / 100)) /
          units[0].unit.value,
        2,
      )
    : roundedPrice(parlor_item.item[price_type] * (1 + parlor_item.pricecategory.percentage / 100));
};

const calculateQuantity = (parlor_item, units) =>
  parlor_item.value * (units?.length ? units[0].unit.value : 1);

export const transformParlorItem = (parlor_item, categories, data, defaultCurrency) => {
  const parentCategory = data ? getParentCategory(parlor_item, categories) : null;
  const hasVariant = checkVariant(parlor_item);
  const isParent = data ? checkIsParent(parlor_item, data) : null;

  const itemName = parlor_item.item.name;
  const variantName = hasVariant ? parlor_item.item.variant.name : '';
  const label = `${itemName} ${variantName}`;

  const category = data
    ? getCategory(parlor_item, parentCategory)
    : parlor_item.item.category?.parent_category
    ? parlor_item.item.category.parent_category.value
    : parlor_item.item.category.value;
  const sub_category = data
    ? getSubCategory(parlor_item)
    : parlor_item.item.category?.parent_category
    ? parlor_item.item.category.value
    : null;

  const units = parlor_item.item.item_units;
  const sell_price = calculateSellPriceExtra(parlor_item, units, 'sell_price', defaultCurrency);
  const shop_price = calculateSellPriceExtra(parlor_item, units, 'shop_price', defaultCurrency);
  const quantity = calculateQuantity(parlor_item, units);

  return {
    value: parlor_item.item.id,
    label: label,
    category: category,
    sub_category: sub_category,
    sell_price: sell_price,
    shop_price: shop_price,
    nds: parlor_item.item.nds,
    parent: parlor_item.item.parent_id,
    is_parent: isParent,
    quantity: quantity,
    store_amount: parlor_item.value,
    image: parlor_item.item.image,
    description: parlor_item.item.description,
    pay_full_points: parlor_item.item.pay_full_points,
    pay_half_points: parlor_item.item.pay_half_points,
    unit: parlor_item.item.units,
    units: transformUnits(parlor_item),
    variant: transformVariant(parlor_item),
    collections: transformCollections(parlor_item),
    marketable: parlor_item.item.marketable,
    expirationdates: parlor_item.expirationdates,
  };
};

const getParentCategory = (parlor_item, categories) =>
  categories.find((cat) => cat.value === parlor_item.item.category.parent)?.value;

const checkVariant = (parlor_item) =>
  parlor_item.item.variant && parlor_item.item.variant.name !== 'main';

const checkIsParent = (parlor_item, data) =>
  data.parlorstore.find((el) => el.item.parent_id === parlor_item.item.id);

const transformUnits = (parlor_item) =>
  parlor_item.item.item_units?.length
    ? {
        value: parlor_item.item.item_units[0].unit.id,
        count: parlor_item.item.item_units[0].unit.value,
        label: `${parlor_item.item.item_units[0].unit.name}`,
        cost: parlor_item.item.item_units[0].unit.cost,
        title: parlor_item.item.item_units[0].unit.title,
      }
    : {};

const transformVariant = (parlor_item) => ({
  label: parlor_item.item?.variant?.name,
  value: parlor_item.item.id,
  cost: parlor_item.item?.variant?.value,
});

const transformCollections = (parlor_item) =>
  parlor_item.item.store_item_collection_attributes.map((item) => ({
    name: item.store_collectionattribute.name,
    id: item.store_collectionattribute.id,
    attributes: item.store_collectionattribute.store_collectionattributes_attributes.map(
      (attribute) => ({
        value: attribute.store_attribute.id,
        label: attribute.store_attribute.name,
        cost: attribute?.store_attribute?.sell_price,
        collection: item?.store_collectionattribute?.id,
      }),
    ),
  }));

const sortDataByLastMonthSales = (data) =>
  data.sort((a, b) => {
    return getLastMonthSell(b.marketable).length - getLastMonthSell(a.marketable).length;
  });

export const useParlorStoreItems = (parlor, categories) => {
  const currencies = useContext(CurrencyContext);
  const defaultCurrency = currencies?.currencies?.find((currency) => currency.value === 1);

  const lang = useLanguage();
  const [loadItems, { called, loading, data, error }] = useLazyQuery(fetchParlorStoreItemsQuery);
  return useMemo(() => {
    if (loading || !called || error) return { items: [], loadItems, loading };
    const items = transformItemsData(data, categories, lang, defaultCurrency);
    return { items, loadItems, loading };
  }, [data, lang, loading, error, called, loadItems, parlor]);
};

export const useParlorStoreItemsСonsumable = (parlor, categories) => {
  const lang = useLanguage();
  const currencies = useContext(CurrencyContext);
  const defaultCurrency = currencies?.currencies?.find((currency) => currency.value === 1);

  const [
    loadItems,
    { called: itemsDataCalled, loading: itemsDataLoading, data: itemsData, error: itemsDataError },
  ] = useLazyQuery(fetchParlorStoreItemsQuery, { fetchPolicy: 'cache-and-network' });

  return useMemo(() => {
    if (itemsDataLoading || itemsDataError) return { items: [], variants: [], loadItems };

    const items = transformItemsData(itemsData, categories, lang, defaultCurrency).filter(
      (item) => {
        return item.variant?.label === 'main' || item.quantity > 0;
      },
    );
    return { items, loadItems, itemsDataLoading };
  }, [itemsData, itemsDataCalled, itemsDataLoading, itemsDataError, loadItems, categories, lang]);
};

export const useParlorStoreItemVariantsСonsumable = (parlor, categories) => {
  const lang = useLanguage();
  const currencies = useContext(CurrencyContext);
  const defaultCurrency = currencies?.currencies?.find((currency) => currency.value === 1);
  const [
    loadVariants,
    {
      called: variantsDataCalled,
      loading: variantsDataLoading,
      data: variantsData,
      error: variantsError,
    },
  ] = useLazyQuery(fetchParlorStoreItemVariantsQuery, { fetchPolicy: 'cache-and-network' });

  return useMemo(() => {
    if (variantsDataLoading || variantsError) return { items: [], variants: [], loadVariants };

    const variants = transformItemsData(variantsData, categories, lang, defaultCurrency);
    return { variants, loadVariants, variantsDataLoading };
  }, [
    variantsData,
    variantsDataCalled,
    variantsDataLoading,
    variantsError,
    loadVariants,
    categories,
    lang,
  ]);
};

export const useParlorStoreAddItems = (categories) => {
  const lang = useLanguage();
  const [loadItems, { called, loading, data, error }] = useLazyQuery(fetchParlorStoreItemsAddQuery);
  return useMemo(() => {
    if (loading || !called || error) return { items: [], loadItems };
    const items = data?.parlorstore?.map((parlor_item) => {
      const parentCategory = categories.find(
        (cat) => cat.value === parlor_item.item.category.parent,
      )?.value;
      return {
        value: parlor_item.item.id,
        label: parlor_item.item.name,
        category: !parlor_item.item.category.parent
          ? parlor_item.item.category.value
          : parentCategory
          ? parentCategory
          : null,
        sub_category: parlor_item.item.category.parent ? parlor_item.item.category.value : null,
        sell_price: parlor_item.item.sell_price,
        nds: parlor_item.item.nds,
        parent: parlor_item.item?.parent_id,
        quantity: parlor_item.value,
        image: parlor_item.item.image,
        description: parlor_item.item.description,
        purchase_price: parlor_item.item.purchase_price,
        is_expiration_date: parlor_item.item.is_expiration_date,
        units: parlor_item.item.store_item_units_news.map((item) => ({
          value: item.store_unit.id,
          label: `${item.store_unit.name} (${item.store_unit.value})`,
          count: item.store_unit.value,
          cost: item.store_unit.cost,
        })),
        variant: {
          label: parlor_item.item?.attribute?.name,
          value: parlor_item.item.id,
          cost: parlor_item.item?.attribute?.value,
        },
        collections: parlor_item.item.store_item_collection_attributes.map((item) => ({
          id: item.store_collectionattribute.id,
          name: item.store_collectionattribute.name,
          attributes: item.store_collectionattribute.store_collectionattributes_attributes.map(
            (attribute) => ({
              value: attribute.store_attribute.id,
              label: attribute.store_attribute.name,
              cost: attribute?.store_attribute?.value,
              collection: item?.store_collectionattribute?.id,
            }),
          ),
        })),
      };
    });
    return { items, loadItems };
  }, [data, lang, loading, error, loadItems, called]);
};

export const useStoreCategoriesAvailable = () => {
  const lang = useLanguage();
  const [loadCategories, { called, data, loading, error }] = useLazyQuery(
    fetchCategoriesQueryAvailable,
  );
  return useMemo(() => {
    if (loading || !called || error)
      return [
        [{ label: 'Все категории', value: 0 }],
        [{ label: 'Все товары', value: 0 }],
        loadCategories,
      ];
    const categories = [
      {
        label: 'Все категории',
        value: 0,
      },
      data?.category
        .filter((el) => !el.parent)
        .map((el) => ({
          ...el,
          label: el.name,
        })),
    ].flat();

    const subCategories = [
      {
        label: 'Все товары',
        value: 0,
      },
      data?.category
        .filter((cat) => cat.parent)
        .map((el) => ({
          ...el,
          label: el.name,
        })),
    ].flat();

    return [categories, subCategories, loadCategories];
  }, [data, lang, loading, error]);
};

const getItemsCreateOrderData = (categories, items) => {
  return items.map((item) => {
    const parentCategory = categories.find((cat) => cat.value === item.category.parent);
    return {
      value: item.id,
      label:
        item.name +
        ' ' +
        (item.store_attribute && item.store_attribute?.name !== 'main'
          ? item.store_attribute.name
          : ''),
      category: !item.category.parent
        ? {
            value: item.category.value,
            label: item.category.name,
          }
        : parentCategory
        ? parentCategory
        : { value: 0, label: 'Все категории' },
      sub_category: item.category.parent
        ? {
            ...item.category,
            label: item.category.name,
          }
        : { value: 0, label: 'Все товары' },
      price: item.sell_price,
      item_code: item.item_code,
      category_id: item.category_id,
      shop_price: item.shop_price,
      provided_price: item.provided_price,
      purchase_price: item.purchase_price,
      purchase_delivery_price: item.purchase_delivery_price,
      wholesale_price: item.wholesale_price,
      sell_other_countries_price: item.sell_other_countries_price,
      sell_price: item.sell_price,
      store_amount: item.store_amount,
      parlor_amount: item.parlor_amount[0]?.value,
      parent: item?.parent_id,
      image: item.image,
      description: item.description,
      nds: item.nds,
      preorders: item.preorders.aggregate.sum.value ?? 0,
      expected_items: item.expected_items.aggregate.sum.value ?? 0,
      sent: item?.sent?.aggregate.sum.value ?? 0,
      units: item.item_units.length
        ? {
            value: item.item_units[0].unit.value,
            label: `${item.item_units[0].unit.name}`,
            cost: item.item_units[0].unit.cost,
            title: item.item_units[0].unit.title,
          }
        : {},
      unit: item?.units,
      variant: {
        label: item?.store_attribute?.name,
        value: item.id,
        cost: item?.store_attribute?.value,
      },
      unofficial_item: item.unofficial_item,
      marketable: item.marketable,
      collections: item.store_item_collection_attributes.map((item) => ({
        id: item.store_collectionattribute.id,
        name: item.store_collectionattribute.name,
        attributes: item.store_collectionattribute.store_collectionattributes_attributes.map(
          (attribute) => ({
            value: attribute.store_attribute.id,
            label: attribute.store_attribute.name,
            cost: attribute?.store_attribute?.value,
            collection: item?.store_collectionattribute?.id,
          }),
        ),
      })),
    };
  });
};

export const useStoreItemAvailable = (userPrice, categories) => {
  const lang = useLanguage();
  const { pathname } = useLocation();
  const isCRMLocation = useMemo(() => pathname.startsWith('/crm/'), [pathname]);
  const [loadItems, { called, loading, data, error }] = useLazyQuery(
    fetchItemsQueryAvailable(isCRMLocation, userPrice),
  );
  return useMemo(() => {
    if (loading || !called || error) return { items: [], loadItems, loading };
    const items = getItemsCreateOrderData(categories, data.items).sort((a, b) => {
      return getLastMonthSell(b.marketable).length - getLastMonthSell(a.marketable).length;
    });
    return { items, loadItems, loading };
  }, [data, lang, loading, error, called, loadItems]);
};

export const useSiteParlorPage = () => {
  const { data, loading, error } = useQuery(fetchSiteParlorPage);

  const siteParlorPage = useMemo(() => {
    return data?.siteParlorPage;
  }, [data]);

  return {
    siteParlorPage,
    loading,
    error,
  };
};

export const useMarketableItems = (isOpenMarketable, categories, parlor) => {
  const { data, loading, error } = useQuery(fetchParlorMarketableItemsQuery, {
    variables: { parlor, from: moment().subtract(1, 'month').format('YYYY-MM-DD') },
    fetchPolicy: 'cache-and-network',
    skip: !isOpenMarketable,
  });
  return useMemo(() => {
    if (!data || !loading || error) return { marketableItems: [], loading };
    const endingItems = getItemsCreateOrderData(categories, data?.items);
    return { marketableItems: endingItems, loading };
  }, [data, parlor]);
};

export const useOrderItemsDeleted = (isDeletedItems, categories, parlor) => {
  const { data, loading, error } = useQuery(fetchOrderItemsDeletedQuery, {
    variables: { parlor, from: moment().subtract(1, 'month').format('YYYY-MM-DD') },
    fetchPolicy: 'cache-and-network',
    skip: !isDeletedItems,
  });

  return useMemo(() => {
    if (!data || !loading || error)
      return { deletedOrderItems: [], loadingDeletedOrderItems: loading };
    const deletedOrderItems = data.store_order
      .map((el) => el.status_history)
      .flat()
      .filter((el) => el.text.includes('Удалил'));
    return { deletedOrderItems: deletedOrderItems, loadingDeletedOrderItems: loading };
  }, [data, parlor]);
};

export const useStoreItemAvailableCustomer = (userPrice, categories) => {
  const lang = useLanguage();
  const [loadItems, { called, loading, data, error }] = useLazyQuery(
    fetchItemsQueryAvailableCustomer(userPrice),
  );

  // const { data: receiver, loading: loadingReceiver } = useQuery(fetchReceiverQuery, {
  //   variables: {
  //     id: user.receiver,
  //   },
  // });
  // const getPriceByType = (item, receiver) => {
  //   return item[receiver.price];
  // };

  return useMemo(() => {
    if (loading || !called || error) return { items: [], loadItems };
    const items = data.items.map((item) => {
      const parentCategory = categories.find((cat) => cat.value === item.category.parent_id);
      return {
        value: item.id,
        label: item.name,
        category: !item.category.parent
          ? item.category.value
          : parentCategory
          ? parentCategory.value
          : 0,
        sub_category: item.category.parent ? item.category.value : 0,
        item_code: item.item_code,
        store_amount: item.store_amount,
        parent: item?.parent_id,
        image: item.image,
        category_id: item.category_id,
        nds: item.nds,
        description: item.description,
        purchase_price: item.purchase_price,
        purchase_delivery_price: item.purchase_delivery_price,
        price: item[userPrice],
        units: item.item_units.map((item) => ({
          value: item.unit.id,
          label: `${item.unit.name} (${item.unit.value})`,
        })),
        variant: {
          label: item?.attribute?.name,
          value: item.id,
          cost: item?.attribute?.value,
        },
        collections: item.collections.map((item) => ({
          id: item.collection.id,
          name: item.collection.name,
          attributes: item.collection.attributes.map((attribute) => ({
            value: attribute.attribute.id,
            label: attribute.attribute.name,
            cost: attribute?.attribute?.value,
            collection: item?.collection?.id,
          })),
        })),
      };
    });
    return { items, loadItems, priceCategory: data.categories[0]?.name };
  }, [data, lang, loading, error, loadItems, called]);
};

export const useStoreItems = (stores, parlor) => {
  const lang = useLanguage();

  const { data, loading, error, refetch } = useQuery(fetchParlorItemsQuery, {
    variables: { parlor, stores },
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    if (loading || error) return [[], [], refetch];
    const cats = data.category.map((el) => ({
      label: el.name,
      value: el.id,
      parent: el.parent_id,
      items: el.items.map((item) => {
        const parentCategory = data.category.find((cat) => cat.id === el.parent_id);
        return {
          value: item.id,
          label:
            item.name +
            ' ' +
            (item.store_attribute && item.store_attribute?.name !== 'main'
              ? item.store_attribute.name
              : ''),
          category: !el.parent_id
            ? { value: el.id, label: el.name }
            : parentCategory
            ? {
                value: parentCategory.id,
                label: parentCategory.name,
              }
            : { value: 0, label: 'Все категории' },
          sub_category: el.parent_id
            ? {
                value: el.id,
                label: el.name,
                parent: el.parent_id,
              }
            : { value: 0, label: 'Все товары' },
          price: item.sell_price,
          unofficial_item: item.unofficial_item,
          item_code: item.item_code,
          sell_price: item.sell_price,
          shop_price: item.shop_price,
          provided_price: item.provided_price,
          store_amount: item.store_amount,
          purchase_price: item.purchase_price,
          purchase_delivery_price: item.purchase_delivery_price,
          sell_other_countries_price: item.sell_other_countries_price,
          wholesale_price: item.wholesale_price,
          parent: item?.parent_id,
          image: item.image,
          nds: item.nds,
          description: item.description,
          is_collection: item.is_collection,
          marketable: item.marketable,
          can_be_produced: item.can_be_produced,
          shop_price_hide: item.shop_price_hide,
          sell_price_hide: item.sell_price_hide,
          provided_price_hide: item.provided_price_hide,
          wholesale_price_hide: item.wholesale_price_hide,
          sell_other_countries_price_hide: item.sell_other_countries_price_hide,
          variant: {
            label: item?.store_attribute?.name,
            value: item.id,
            cost: item?.store_attribute?.value,
          },
          unit: item?.units,
          units: item.item_units.length
            ? {
                value: item.item_units[0].unit.id,
                label: `${item.item_units[0].unit.name}`,
                count: item.item_units[0].unit.value,
                cost: item.item_units[0].unit.cost,
                title: item.item_units[0].unit.title,
              }
            : {},
          collections: item.store_item_collection_attributes.map((item) => ({
            id: item.store_collectionattribute.id,
            name: item.store_collectionattribute.name,
            attributes: item.store_collectionattribute.store_collectionattributes_attributes.map(
              (attribute) => ({
                value: attribute.store_attribute.id,
                label: attribute.store_attribute.name,
                cost: attribute?.store_attribute?.value,
                collection: item?.store_collectionattribute?.id,
              }),
            ),
          })),
          purchase_price_proposition: item.store_itemstorepropositions.find(
            (proposition) => proposition.purchase_price !== null,
          ),
          nds_proposition: item.store_itemstorepropositions.find(
            (proposition) => proposition.nds !== null,
          ),
          existed_proposition_purchase_price: item.store_itemstorepropositions.find(
            (proposition) => proposition.purchase_price !== null,
          ),
          existed_proposition_nds: item.store_itemstorepropositions.find(
            (proposition) => proposition.nds !== null,
          ),
        };
      }),
    }));
    return [cats, refetch];
  }, [data, loading, lang, error, refetch]);
};

export const useParlors = (addAll = false, allParlors = false, onlyParlor = false) => {
  const { user } = useContext(UserContext);
  const { data, loading, error } = useQuery(fetchParlorsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return [];
  }

  const transformParlor = (el) => ({
    label: el.name,
    value: el.id,
    is_grant: el.is_grant,
    status: el.status,
    islaser: el?.is_laser,
    is_always_laser: el.is_always_laser,
    fullRange: el?.full_range_of_services,
    native_speaker: el?.native_speaker,
    junior_master: el?.junior_master,
    place_of_study: el.place_of_study,
    need_duty_master: el.need_duty_master,
    number_of_jobs: el?.number_of_jobs,
    number_maximum_workplaces: el?.number_maximum_workplaces,
    is_rent: el?.is_rent,
    isFlagman: el?.is_flagman,
    laserComment: el?.laser_comment,
    temporary_inactive: el?.temporary_inactive_lounge,
    director: {
      label: [el?.director?.first_name, el?.director?.middle_name, el?.director?.last_name]
        .join(' ')
        .trim(),
      value: el?.director?.id,
    },
  });

  let processedParlors;
  if (allParlors) {
    processedParlors = data.core_parlor.map(transformParlor);
  } else if (isRole(['master', 'administrator', 'director', 'student', 'security'], user)) {
    processedParlors = user.parlors;
  } else {
    processedParlors = data.core_parlor.map(transformParlor);
  }

  let parlors = processedParlors;

  if (onlyParlor) {
    parlors = parlors.filter((el) => el.status === 100);
  }
  parlors = parlors.sort((a, b) => (a.label > b.label ? 1 : -1));

  if (addAll) {
    return [{ label: 'Все салоны (all salons)', value: null }, ...parlors];
  } else {
    return parlors;
  }
};

export const useParlorFilter = (isMulti = false) => {
  const { t } = useTranslation();
  const parlors = useParlors();
  const [parlor, setParlor] = useState(null);
  return [
    parlor,
    <Select
      name='parlor'
      options={parlors}
      styles={selectStyles(false)}
      isMulti={isMulti}
      onChange={(e) => setParlor(e.value)}
      placeholder={t('chart.all_parlors')}
    />,
  ];
};

export const useTextFilter = ({ placeholder = '', delay = 300 }) => {
  const [text, setText] = useState('');

  // Создаем debounced версию setText
  const debouncedSetText = useMemo(
    () => lodash.debounce((value) => setText(value), delay),
    [delay],
  );

  const handleChange = (e) => {
    const { value } = e.target;
    debouncedSetText(value);
  };

  // Очищаем debounce при размонтировании компонента
  useMemo(() => {
    return () => debouncedSetText.cancel();
  }, [debouncedSetText]);

  return [text, <Input type='text' placeholder={placeholder} onChange={handleChange} />];
};

export const useWorkTax = () => {
  const { data, error } = useQuery(fetchUseWorkTax, {
    fetchPolicy: 'cache-and-network',
  });
  const [workTax, setWorkTax] = useState([]);
  if (error) {
    console.log(error, 'ERROR workTax');
  }
  useEffect(() => {
    if (data?.motivation_worktax) {
      setWorkTax(
        data?.motivation_worktax.map((el) => ({
          value: el?.id,
          hours: el?.hours,
          employee_tax: el?.employee_tax,
          salary_netto: el?.salary_netto,
          employer_tax: el?.employer_tax,
          motivation_workcontract_name: el?.motivation_workcontract.name,
          motivation_workcontract_description: el?.motivation_workcontract.description,
          motivation_workcontract_id: el?.motivation_workcontract.id,
        })),
      );
    }
  }, [data]);

  return workTax;
};
export const useSessionStyle = () => {
  const { data } = useQuery(STYLE);
  const styles = useMemo(
    () =>
      data?.styles.map((el) => ({
        label: el.name,
        value: el.id,
      })) ?? [],
    [data],
  );
  return styles;
};
export const useSessionTypes = (userId = null, isActive = null) => {
  const variables = useMemo(() => {
    let varObj = {};
    if (userId) varObj.userId = userId;
    if (isActive !== null) varObj.isActive = isActive;
    return varObj;
  }, [userId, isActive]);

  const { data } = useQuery(fetchSessionTypesQuery, {
    variables: variables,
    fetchPolicy: 'cache-and-network',
  });
  const [types, setTypes] = useState([]);
  const currentLanguage = i18next.languages[0] === 'ukr' ? 'uk' : i18next.languages[0];

  useEffect(() => {
    if (data?.apps_sessions_sessiontype) {
      setTypes(
        data?.apps_sessions_sessiontype?.map((el) => ({
          value: el.id,
          show: el.show,
          prepayment: el?.prepayment,
          isNeedModerate: el.is_need_moderate,
          type: el.type,
          label: el[`name_${currentLanguage}`] || el.name,
          text: el[`introductory_text_${currentLanguage}`] || '',
          sizes: el.sizes.map((el) => ({
            value: el.id,
            label: `${el.size}`,
          })),
          locations: el.locations.map((el) => ({
            value: el.value,
            label: el[`name_${currentLanguage}`] || el.name,
            text: el.text,
          })),
          styles: el.styles.map((el) => ({
            value: el.style.id,
            label: el.style.name,
          })),
        })),
      );
    }
  }, [variables, currentLanguage, data]);
  return types;
};

export const useEmployees = (shop = null, role = false, student = false) => {
  const client = useApolloClient();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const getQuery = (extra) => {
    if (shop) return fetchEmployeesQueryFromShop;
    if (student) return fetchStudentQuery;
    if (role) return fetchEmployeesQueryByRole;
    if (Object.keys(extra).length) return fetchFilteredEmployeesQuery;
    return fetchEmployeesQuery;
  };
  const fetchEmployees = (parlor, extra = {}) => {
    setLoading(true);
    const query = getQuery(extra);
    client
      .query({
        query,
        variables: { parlorId: parlor, ...extra },
      })
      .then(({ data }) => {
        setEmployees(
          data.accounts_user
            .slice()
            .sort((a, b) => {
              const aSessionMotivations = a?.motivation?.sessionmotivations || [];
              const bSessionMotivations = b?.motivation?.sessionmotivations || [];

              // Проверка на пустоту массива sessionmotivations
              if (aSessionMotivations.length === 0) return 1;
              if (bSessionMotivations.length === 0) return -1;

              // Сначала сортируем по parlor_id
              const aParlorId = a?.profile?.parlor_id;
              const bParlorId = b?.profile?.parlor_id;

              if (aParlorId === parlor && bParlorId !== parlor) return -1;
              if (aParlorId !== parlor && bParlorId === parlor) return 1;

              // Если parlor_id одинаковый или оба не равны specificParlorId, сортируем по percent
              return aSessionMotivations[0].percent - bSessionMotivations[0].percent;
            })
            .map((el) => ({
              label: `${joinUserNames(el)}${
                el.studentProfile?.who_studing === 'guest'
                  ? '(Гостевой)'
                  : el.studentProfile?.who_studing === 'student'
                  ? '(Студент)'
                  : ''
              }`,
              value: el.id,
              role: el.role,
              isGuest: el.studentProfile?.who_studing === 'guest',
              studentId: el?.student?.id,
            })),
        );
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  return [employees, fetchEmployees, loading];
};

export const useParlorEmployees = () => {
  const { parlor } = useContext(ParlorContext);
  const [employees, fetchEmployees] = useEmployees();
  useEffect(() => {
    fetchEmployees(parlor);
  }, [parlor, fetchEmployees]);
  return employees;
};

export const useFindOutOptions = () => {
  const client = useApolloClient();
  const [findOutOptions, setFindOutOptions] = useState([]);
  const lang = useLanguage();

  useEffect(() => {
    client
      .query({
        query: fetchFindOutOptionsQuery,
      })
      .then(({ data: { customers_findout } }) => {
        setFindOutOptions(
          customers_findout.map((el) => ({
            value: el.id,
            label: el.name,
          })),
        );
      })
      .catch(console.log);
  }, [client, lang]);

  return findOutOptions;
};

export const useAdvertTypes = () => {
  const { data, loading } = useQuery(fetchAdvertTypesQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [advertTypes, setAdvertTypes] = useState([]);
  const lang = useLanguage();
  const { user } = useContext(UserContext);
  const currentLanguage = ['ukr', 'ukrG'].includes(i18next.languages[0])
    ? 'uk'
    : i18next.languages[0].replace(/G$/, '');
  useEffect(() => {
    if (data?.advertising_adverttype) {
      setAdvertTypes(
        data?.advertising_adverttype
          ?.filter((f) =>
            isRole(['director'], user)
              ? f.advertising_roles.find((role) => role.role == 'director')
              : isRole(['administrator'], user)
              ? f.advertising_roles.find((role) => role.role == 'administrator')
              : isRole(['master'], user)
              ? f.advertising_roles.find((role) => role.role == 'master')
              : true,
          )
          .map((el) => {
            return {
              label:
                el.kind_type?.name.toLowerCase() === 'реклама управляющего'
                  ? (
                      <div style={{ backgroundColor: '#0066b2', padding: '5px' }}>
                        {el[`name_${currentLanguage}`]}{' '}
                      </div>
                    ) || (
                      <div style={{ backgroundColor: '#0066b2', padding: '5px' }}>{el.name_ru}</div>
                    ) ||
                    ''
                  : el[`name_${currentLanguage}`] || el.name_ru || '',
              value: el.id,
              note: el[`note_${currentLanguage}`] || el.note_ru || '',
              info: el[`info_${currentLanguage}`] || el.info_ru || '',
              note_old: el.note_old,
              kindTypeId: el.kind_type_id,
              mandatory_advertising: el.mandatory_advertising,
              name: el.name,
              kindTypeName: el.kind_type?.name,
              cooperating_contract: el.cooperating_contract,
            };
          }),
      );
    }
  }, [lang, user, currentLanguage, data]);
  if (loading) {
    return [];
  }
  return advertTypes;
};

export const useAdvertKindTypes = () => {
  const client = useApolloClient();
  const [advertKindTypes, setAdvertKindTypes] = useState([]);
  const lang = useLanguage();
  useEffect(() => {
    client
      .query({
        query: fetchAdvertKindTypesQuery,
      })
      .then(({ data: { advertising_advertkindtype } }) => {
        setAdvertKindTypes(
          advertising_advertkindtype
            .filter((f) => f.name)
            .map((el) => ({
              label: el.name,
              value: el.id,
            })),
        );
      })
      .catch(console.log);
  }, [client, lang]);

  return advertKindTypes;
};

export const useTypeEducation = () => {
  const { data, error } = useQuery(TYPEEDUCATION, { fetchPolicy: 'cache-and-network' });
  const type = data?.typeEducations?.map((el) => ({
    label: el.name,
    value: el.id,
  }));
  if (error) {
    console.log(error);
  }
  return type;
};

export const useCustomerFormStatuses = () => {
  const { data, error } = useQuery(CUSTOMER_FORM_STATUS);

  const options = useMemo(() => {
    if (!data) return [];
    else
      return data.customers_formstatus.map((el) => ({
        label: el.name,
        value: el.id,
      }));
  }, [data]);
  return { data, error, options };
};

export const useCustomerFormNames = () => {
  const { data, error } = useQuery(SITE_FORM_NAMES);
  return { data: data?.formNames.nodes, error };
};
export const useCustomerFormResponsibles = () => {
  const { data, error, loading } = useQuery(SITE_FORM_RESPONSIBLES);

  return { data: data?.responsibles.nodes, error, loading };
};
export const useFineTypes = () => {
  const client = useApolloClient();
  const [fineTypes, setFineTypes] = useState([]);
  const lang = useLanguage();

  useEffect(() => {
    client
      .query({
        query: fineTypesQuery,
      })
      .then(({ data: { accounts_finetype } }) => {
        setFineTypes(
          accounts_finetype.map((el) => ({
            value: el.id,
            label: el.name,
          })),
        );
      })
      .catch(console.log);
  }, [lang, client]);

  return fineTypes;
};

export const useCollectionAttributes = () => {
  const client = useApolloClient();
  const [collectionAttributes, setCollectionAttributes] = useState([]);

  useEffect(() => {
    client
      .query({
        query: fetchCollectionAttributesQuery,
      })
      .then(({ data: { collections } }) => {
        setCollectionAttributes(collections);
      })
      .catch(console.log);
  }, [client]);

  return { collections: collectionAttributes, setCollections: setCollectionAttributes };
};

export const useAttributes = () => {
  const client = useApolloClient();
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    client
      .query({ query: fetchAttributesQuery })
      .then(({ data: { attribute } }) => {
        setAttributes(attribute);
      })
      .catch(console.log);
  }, [client]);

  return { attributes, setAttributes };
};

export const useDateFilter = (variableName, defaultValue, label = null, isBlockMinDate = true) => {
  const { user } = useContext(UserContext);
  const userIsMarketing = user?.marketing_tag?.id !== undefined;
  let minDate = '';
  if (isBlockMinDate === true) {
    // Проверяем наличие двухфакторной аутентификации (OTP)
    if (user.requireOtp) {
      if (user.is_superuser || ['Oleksandr_Khandan'].includes(user.username)) {
        // Для суперпользователей ничего не меняем, оставляем minDate пустым
        minDate = '';
      } else if (user.isRegionalManager || userIsMarketing) {
        // Региональные менеджеры — 12 месяцев назад
        minDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
      } else if (['director', 'owner'].includes(user.role)) {
        // Директоры и владельцы — 6 месяцев назад
        minDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else {
        // Остальные пользователи с требуемым OTP — 3 месяца назад
        minDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      }
    } else {
      // Если OTP не требуется — 1 месяц назад
      minDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    }
  } else if (isBlockMinDate === 'student') {
    minDate = moment().subtract(48, 'months').format('YYYY-MM-DD');
  } else if (isBlockMinDate === 'administrator') {
    minDate = moment().subtract(3, 'days').format('YYYY-MM-DD');
  } else if (
    isBlockMinDate === 'salary' &&
    (isRole(['owner'], user) || user.username === 'Kseniia_Lapaa')
  ) {
    minDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
  } else if (isBlockMinDate === 'salary') {
    minDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  } else if (isBlockMinDate === 'advertising_content' && isRole(['owner'], user)) {
    minDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
  } else if (isBlockMinDate === 'advertising_content') {
    minDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  }

  const [date, setDate] = useSearchState(variableName, defaultValue);
  const debouncedSetDate = lodash.debounce(setDate, 500);

  const handleDateChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value && moment(value).isBefore(moment(minDate))) {
        return;
      }
      if (value) {
        debouncedSetDate(value);
      }
    },
    [debouncedSetDate, minDate],
  );

  return [
    date,
    <FormGroup>
      {label && (
        <Label>
          <p>{label}</p>
        </Label>
      )}
      <Input type='date' value={date} onChange={handleDateChange} min={minDate} required={true} />
    </FormGroup>,
  ];
};

export const useBankCurrency = () => {
  const { api } = useContext(UserContext);
  const [currency, setCurrency] = useState(NaN);
  useEffect(() => {
    api
      .get(`/core/currency/`)
      .then(({ data }) => {
        setCurrency(data.currency);
      })
      .catch(console.log);
  }, [api]);
  const nacBank = useMemo(() => currency, [currency]);
  const minFin = useMemo(() => parseFloat(parseFloat(currency * 1.02).toFixed(2)), [currency]);
  return [minFin, nacBank];
};

export const useExceptionChoices = () => {
  const { api } = useContext(UserContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    api
      .get(`/exception-choices/`)
      .then(({ data }) => {
        setData(data);
      })
      .catch(console.log);
  }, [api]);
  return data;
};

export const useCustomersCount = () => {
  const validateDate = moment().subtract(3, 'days').startOf('day');
  const { data } = useQuery(fetchCustomersCountQuery, {
    variables: {
      from: moment().startOf('month'),
      fromT: moment().startOf('month').format('YYYY-MM-DD'),
      nowTime: moment().format('HH:mm:ss'),
      nowDay: moment().format('YYYY-MM-DD'),
      validateDate: validateDate,
    },
  });

  const fromSiteCount = useMemo(() => {
    if (data?.from_site) {
      return data?.from_site.aggregate.count;
    } else {
      return null;
    }
  }, [data]);

  const leadsCount = useMemo(() => {
    if (data?.leads) {
      return data?.leads.aggregate.count;
    } else {
      return null;
    }
  }, [data]);

  const canceledCount = useMemo(() => {
    if (data?.canceled_session_count) {
      return data?.canceled_session_count.aggregate.count;
    } else {
      return null;
    }
  }, [data]);

  const siteformCount = useMemo(() => {
    if (data?.leads) {
      return data?.siteform_count.aggregate.count;
    } else {
      return null;
    }
  }, [data]);
  const noGoodCallStudent = useMemo(() => {
    if (data?.studentNoCallCount) {
      return data?.studentNoCallCount.aggregate.count;
    } else {
      return null;
    }
  }, [data]);
  const overDueSession = useMemo(() => {
    if (data?.overDueSession) {
      return data?.overDueSession.aggregate.count;
    } else {
      return null;
    }
  }, [data]);
  const startStudingStudent = useMemo(() => {
    if (data?.studentStartCount) {
      return data?.studentStartCount.aggregate.count;
    } else {
      return null;
    }
  }, [data]);

  return {
    fromSiteCount,
    leadsCount,
    canceledCount,
    siteformCount,
    noGoodCallStudent,
    overDueSession,
    startStudingStudent,
    problemsituationCustomer: data?.problemsituation_customer?.aggregate?.count ?? 0,
  };
};

export const useUserByUsername = (username) => {
  const { data, loading, error } = useQuery(fetchUsernameByUser, {
    variables: { username },
  });

  return { data, loading, error };
};

export const useDiplomDate = (id) => {
  const { data, loading, error } = useQuery(fetchPhotoDiplom, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error) {
    return null;
  }
  const dates = data?.accounts_awardsdiplomas;
  if (!dates) {
    return null;
  }
  const findLatestDate = (dates) => {
    if (!dates || dates.length === 0) {
      return null;
    }
    return dates.reduce((latest, item) => {
      if (!item.cooperation_period) {
        return latest;
      }
      const date = new Date(item.cooperation_period);
      return date > latest ? date : latest;
    }, new Date(-8640000000000000));
  };

  const latestDate = findLatestDate(dates);
  return latestDate;
};

export const useSessionSize = () => {
  const { data, loading, error } = useQuery(fetchSessionSize);
  const size = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const queryData = data?.size;
    return {
      value: queryData.id,
      label: queryData.size,
    };
  }, [data, loading, error]);
  return size;
};
export const useSessionTypeSubcategories = () => {
  const { data, loading, error } = useQuery(fetchSessionTypeSubcategories);
  const sessionType = useMemo(() => {
    if (loading || error) {
      return null;
    }

    const queryData = data?.subSessionType;

    return queryData.map((el) => ({
      value: el.id,
      label: el.name,
      sessionType: el.session?.flatMap((el) => ({
        value: el?.sessionType?.id,
        prepayment: el.sessionType?.prepayment,
        show: el?.sessionType?.show,
        isNeedModerate: el?.sessionType?.is_need_moderate,
        label: el?.sessionType[`name_${i18next.language}`] || el?.sessionType?.name,
        text: el.sessionType[`introductory_text_${i18next.language}`] || '',
        sizes: el?.sessionType?.sizes?.map((el) => ({
          value: el.id,
          label: `${el.size}`,
        })),
        locations: el?.sessionType?.locations?.map((el) => ({
          value: el.value,
          label: el[`name_${i18next.language}`] || el.name,
          text: el.text,
        })),
        styles: el?.sessionType?.styles?.map((el) => ({
          value: el.style.id,
          label: el.style.name,
        })),
      })),
    }));
  }, [data, loading, error]);

  return sessionType;
};

export const useSearchStudents = () => {
  const [loadCustomer, { data, loading, error }] = useLazyQuery(fetchStudentsSearch, {
    fetchPolicy: 'cache-and-network',
  });
  const fetchData = (phone) => {
    loadCustomer({ variables: { phone: `%+${phone.replace('+', '')}%` } });
  };
  const student = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return data?.students_student[0];
  }, [data, loading, error]);

  return { student, fetchData };
};

export const useCouchPrice = () => {
  const { data, loading, error } = useQuery(fetchCouchPrice, {
    fetchPolicy: 'cache-and-network',
  });
  const priceCouch = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return data?.core_couchprice.map((el) => ({
      label: `${el?.name} - ${el?.type} - ${el?.price}`,
      value: el.id,
      price: el?.price,
      type: el?.type,
      type_price: el?.type_price,
    }));
  }, [data, loading, error]);

  return priceCouch;
};

export const useCountServiceModeration = (user) => {
  const { data, loading, error } = useQuery(SKILL_BOX_MODERATION_CONT, {
    fetchPolicy: 'cache-and-network',
    variables: { user_id: user },
  });
  const service_count = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return data?.accounts_awardsdiplomas_aggregate.ag.count;
  }, [data, loading, error]);

  return service_count;
};

export const useDateCooperation = (user) => {
  const { data, loading, error } = useQuery(DATE_COOPERATION, {
    fetchPolicy: 'cache-and-network',
    variables: { user_id: user },
  });
  const service_count = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const dates = data?.accounts_awardsdiplomas
      .map((session) => session.cooperation_period)
      .filter((date) => date !== null);
    const maxDate = dates.length ? moment.max(dates.map((date) => moment(date))) : null;

    return maxDate;
  }, [data, loading, error]);

  return service_count;
};
export const getCurrentWeekDates = () => {
  const startOfWeek = moment().startOf('week').add(1, 'days');
  const endOfNextWeek = moment().startOf('week').add(14, 'days');

  let dates = [];
  let day = startOfWeek;

  while (day <= endOfNextWeek) {
    dates.push({
      value: day.format('YYYY-MM-DD'),
      label: day.format('DD MMMM, dddd'),
    });
    day = day.clone().add(1, 'day');
  }

  return dates;
};

export const useTypeStudentPayments = () => {
  const { data, loading, error } = useQuery(fetchTypeStudentPayments, {
    fetchPolicy: 'cache-and-network',
  });
  const type_payments = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const types = data?.type_payments.map((type) => ({
      label: `${type.name} - ${type.parts_start_lesson}`,
      value: type.id,
    }));

    return types;
  }, [data, loading, error]);

  return type_payments;
};

export const useGangetByParlor = (parlor) => {
  const [LoadData, { data, loading, error }] = useLazyQuery(fetchGadgetsByParlor, {
    variables: { salon: parlor },
    fetchPolicy: 'cache-and-network',
  });
  const gadgetsData = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const gadget = data?.accounts_usergadget.map((gadget) => ({
      label: `${gadget.description} - ${gadget?.accounts_gadgetname?.name} - ${gadget?.serial_number}`,
      value: gadget.id,
      type: gadget?.accounts_gadgetname?.id,
    }));

    return gadget;
  }, [data, loading, error]);

  return { gadgetsData, LoadData };
};

export const useAttendanceTheme = () => {
  const { data, loading, error } = useQuery(fetchAttendanceTheme, {
    fetchPolicy: 'cache-and-network',
  });
  const AttendanceThemeData = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const AttendanceThemeOptions = data?.attendancetheme.map((el) => ({
      label: el.name,
      value: el.id,
    }));

    return AttendanceThemeOptions;
  }, [data, loading, error]);

  return AttendanceThemeData;
};

export const useDirectorByParlor = (parlor) => {
  const { data, loading, error } = useQuery(fetchDirectorByParlor, {
    variables: { parlor: parlor },
    fetchPolicy: 'cache-and-network',
  });
  const directorOptions = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const director = {
      label: joinUserNames(data?.core_parlor_by_pk.director),
      value: data?.core_parlor_by_pk?.director?.id,
    };

    return director;
  }, [data, loading, error]);

  return directorOptions;
};

export const useNeedStyleCount = (parlor) => {
  const { data, loading, error } = useQuery(fetchNeedStyleCount, {
    fetchPolicy: 'cache-and-network',
  });
  const needStyle = useMemo(() => {
    if (loading || error) {
      return null;
    }
    const count = data?.apps_sessions_moderationneedstyle_aggregate?.ag.count;

    return count;
  }, [data, loading, error]);

  return needStyle;
};

export const useAllCountNavBar = (user, parlor, allParlorUser) => {
  const [from, renderFrom] = useDateFilter('fromM', moment().startOf('month').format('YYYY-MM-DD'));
  const [to, renderTo] = useDateFilter('toM', moment().endOf('month').format('YYYY-MM-DD'));
  const dateJoined = moment(user.date_joined);
  const dateThreeMonthsAgoStartOfMonth = moment().subtract(1, 'months').startOf('month');
  const {
    data: dataSalary,
    loading: loadingSalary,
    error: errorSalary,
  } = useQuery(WITHDRAW_SALARY_COUNT, {
    variables: {
      user_id: user.id,
      dateFrom: dateThreeMonthsAgoStartOfMonth,
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: dataNews,
    loading: loadingNews,
    error: errorNews,
  } = useQuery(NEWS_COUNT, {
    variables: {
      role: user.role,
      user: user.id,
      parlor: [
        ...user.parlors.map((el) => el.value),
        ...(user.main_parlor ? [user.main_parlor] : []),
      ],
      dateFrom: dateJoined.subtract(7, 'days'),
    },
  });
  const {
    data: dataDispute,
    loading: loadingDispute,
    error: errorDispute,
  } = useQuery(DISPUTE_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: user.id,
    },
  });
  const {
    data: dataOpenDispute,
    loading: loadingOpenDispute,
    error: errorOpenDispute,
  } = useQuery(OPEN_DISPUTE_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      created_by: user.id,
    },
  });
  const {
    data: dataCheckListInfo,
    loading: loadingCheckListInfo,
    error: errorCheckListInfo,
  } = useQuery(CHECK_LIST_INFO_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: { userId: user.id },
  });

  const {
    data: dataStudent,
    loading: loadingStudent,
    error: errorStudent,
  } = useQuery(STUDENT_CLOSING_MODER_COUNT, {
    variables: {
      parlor: allParlorUser,
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: dataTask,
    loading: loadingTask,
    error: errorTask,
  } = useQuery(USER_TASKS_COUNT, {
    variables: {
      assigned_to: user.id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: dataSesionForm,
    loading: loadingSesionForm,
    error: errorSesionForm,
  } = useQuery(SESSIONS_FORM, {
    variables: {
      parlors: user.parlors.map((el) => el.value),
    },
    fetchPolicy: 'cache-and-network',
  });

  const formCount = useMemo(() => {
    if (loadingSesionForm || errorSesionForm) {
      return 0;
    }
    return dataSesionForm?.sessions?.ag.count + dataSesionForm?.sessionPrepayment?.ag.count;
  }, [dataSesionForm, loadingSesionForm, errorSesionForm]);

  const disputeCount = useMemo(() => {
    if (loadingDispute || errorDispute) {
      return 0;
    }
    return dataDispute?.dispute_count?.ag.count;
  }, [dataDispute, loadingDispute, errorDispute]);

  const openDisputeCount = useMemo(() => {
    if (loadingOpenDispute || errorOpenDispute) {
      return 0;
    }
    return dataOpenDispute?.open_dispute_count?.ag.count;
  }, [dataOpenDispute, loadingOpenDispute, errorOpenDispute]);

  const checkListInfo = useMemo(() => {
    if (loadingCheckListInfo || errorCheckListInfo || loadingStudent || errorStudent) {
      return 0;
    }
    const countStudent = dataStudent?.students?.ag.count || 0;
    const countAgreement = dataCheckListInfo?.checklistAgreement?.ag.count || 0;
    const countEvent = dataCheckListInfo?.eventPlannerChecklistEvent?.ag.count || 0;
    return countStudent + countAgreement + countEvent;
  }, [
    dataCheckListInfo,
    dataStudent,
    loadingCheckListInfo,
    errorCheckListInfo,
    loadingStudent,
    errorStudent,
  ]);
  const taskCount = useMemo(() => {
    if (loadingTask || errorTask) {
      return 0;
    }
    return dataTask?.tasks?.ag.count;
  }, [dataTask, loadingTask, errorTask]);

  const newsCount = useMemo(() => {
    if (loadingNews || errorNews) {
      return 0;
    }
    return dataNews?.articles.ag.count;
  }, [dataNews, loadingNews, errorNews]);

  const salaryCount = useMemo(() => {
    if (loadingSalary || errorSalary) {
      return 0;
    }
    return dataSalary?.salary_withdraw_aggregate.ag.count;
  }, [dataSalary, loadingSalary, errorSalary]);

  return {
    checkListInfo,
    openDisputeCount,
    disputeCount,
    taskCount,
    newsCount,
    salaryCount,
    formCount,
  };
};

export const useLocale = () => {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    const storedLang = (localStorage.getItem('selectedLanguage') || '').trim();
    const cleanedLang = import.meta.env.DEV
      ? storedLang
      : storedLang.endsWith('G')
      ? storedLang.slice(0, -1)
      : storedLang;

    const processedLocale = cleanedLang === 'ukr' ? 'uk' : cleanedLang || 'en';

    setLocale(processedLocale);
  }, []);

  const getLocalizedValue = (obj, key) => {
    const localizedKey = `${key}_${locale}`;
    return obj[localizedKey] || obj[key];
  };

  return { locale, getLocalizedValue };
};

export const useNeedPaymentCategory = () => {
  const { data, loading, error } = useQuery(NEED_PAYMENT_CATEGORY, {
    fetchPolicy: 'cache-and-network',
  });
  const category = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return data?.students_category?.map((el) => ({ label: el.name, value: el.id }));
  }, [data, loading, error]);

  return category;
};

export const useActiveSessions = (user, variables) => {
  const { data, loading, error } = useQuery(ACTIVE_SESSIONS, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const sessions = useMemo(() => {
    if (loading || error || !user) return [];
    if (isRole(['master'], user)) {
      return data.sessions.filter((session) => session.master?.id === user.id);
    }
    return data.sessions;
  }, [user, loading, error, data]);
  return { data, loading, error, sessions };
};

export const useActiveUsers = () => {
  const { api } = useContext(UserContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/active-users/`)
      .then((response) => {
        setResponse(response.data);
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setIsLoading(false));
  }, []);

  const activeUsers = useMemo(() => {
    if (isLoading && !response.length) {
      return [];
    }

    return response;
  }, [response, isLoading]);

  return activeUsers;
};

export const useWallets = (parlorId = null, isValues = true) => {
  const { api } = useContext(UserContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/wallet-options/', {
        params: {
          parlor: parlorId,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setIsLoading(false));
  }, [parlorId]);

  const wallets = useMemo(() => {
    if (isLoading && !response.length) {
      return [];
    }
    if (isValues === true) {
      return response?.reduce(
        (prev, next) => ({
          ...prev,
          [next.id]: next.name,
        }),
        {},
      );
    } else if (isValues === false) {
      return response.map((wallet) => ({
        value: wallet.id,
        label: `${wallet.name} (${wallet?.parlor?.name})`,
        ...wallet,
      }));
    }
    return response;
  }, [response, isLoading, isValues]);

  return wallets;
};

export const useCountriesCRM = ({ excludeCurrentCountry = true }) => {
  const { api_v2 } = useContext(UserContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api_v2
      .get('/core/country-configuration/')
      .then((response) => {
        setResponse(response.data);
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setIsLoading(false));
  }, []);

  const countries = useMemo(() => {
    if (isLoading && !response.length) {
      return [];
    }
    const result = response.reduce((acc, current) => {
      // if (!excludeCurrentCountry || current.api !== 'cr.vean-tattoo.pl') {
      acc.push({
        value: current.id,
        label: current.country,
        api: current.api,
      });
      // }
      return acc;
    }, []);
    return result;
  }, [response, isLoading, excludeCurrentCountry]);

  return countries;
};

export const useSelects = () => {
  function reducer(state, action) {
    state = new Set(state);

    if (action.reset) {
      return new Set();
    }

    if (action.add) {
      if (Array.isArray(action.value)) {
        action.value.forEach((el) => state.add(el.id));
        return state;
      }

      state.add(action.value);
      return state;
    } else {
      if (Array.isArray(action.value)) {
        action.value.forEach((el) => state.delete(el.id));
        return state;
      }

      state.delete(action.value);
      return state;
    }
  }
  const [select, dispatch] = useReducer(reducer, new Set());
  return [select, dispatch];
};

export const useRevenueParlors = ({ dateFrom, dateTo, parlors = [] }) => {
  const { api } = useContext(UserContext);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/revenue-total/', {
        params: {
          parlors: parlors,
          from: dateFrom,
          to: dateTo,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setIsLoading(false));
  }, []);

  const revenue = useMemo(() => {
    if (isLoading && response && Object.entries(response).length === 0) {
      return 0;
    }

    return response?.revenue;
  }, [response, isLoading]);

  return revenue;
};

export const useStores = () => {
  const { api_v2 } = useContext(UserContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api_v2
      .get('/store/store-list/')
      .then((response) => {
        setResponse(response.data);
      })
      .catch(handleAxiosErrorSwal)
      .finally(() => setIsLoading(false));
  }, []);

  const stores = useMemo(() => {
    if (isLoading && !response.length) {
      return [];
    }
    const result = response.reduce((acc, current) => {
      acc.push({
        value: current.id,
        label: current.name,
      });

      return acc;
    }, []);
    return result;
  }, [response, isLoading]);

  return stores;
};

export const useGadgetsName = () => {
  const { data, loading, error } = useQuery(GADGET_NAME, {
    fetchPolicy: 'cache-and-network',
  });
  const gadgetName = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return data?.gadget?.map((el) => ({ label: el.name, value: el.id }));
  }, [data, loading, error]);

  return gadgetName;
};

export const useTypeProofs = () => {
  const { data, loading, error } = useQuery(TYPEPROOFS, {
    fetchPolicy: 'cache-and-network',
  });
  const typeProofs = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data?.tasktypeveancoin?.map((el) => ({ label: el.name, value: el.id }));
  }, [data, loading, error]);

  return typeProofs;
};

export const useCategoryIncomingPayment = () => {
  const { data, loading, error } = useQuery(CATEGORY_INCOMING_PAYEMNT);
  const categories = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data?.categories?.map((el) => ({ label: el.name, value: el.id }));
  }, [data, loading, error]);

  return categories;
};
