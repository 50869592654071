import { gql, useQuery } from '@apollo/client';
import { faBoxOpen, faEdit, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import { handleAxiosErrorSwal } from 'helpers';
import { Input as FormikInput } from 'components/Forms/FormikComponents';
import { UserContext } from 'src/context.jsx';
import { Unboxing } from './Unboxing';

const EXPIRATION_DATE = gql`
  query itemExpirationDate($itemId: Int) {
    expirationdates: store_parlorstoreexpirationdate(
      where: { is_active: { _eq: true }, parlor_store_id: { _eq: $itemId } }
      order_by: { end_date: asc }
    ) {
      id
      is_active
      end_date
      is_opened
      lot
      transactions: store_parlorstoreexpirationdatetransactions(limit: 1, order_by: { id: desc }) {
        quantity
      }
    }
  }
`;

export const ExpirationDate = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUnboxing, setIsOpenUnboxing] = useState(false);

  const { t } = useTranslation();
  const { user, api } = useContext(UserContext);
  const [editExpirationDate, setEditExpirationDate] = useState(null);

  const { data, refetch } = useQuery(EXPIRATION_DATE, {
    variables: {
      itemId: item.id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isOpen && !isOpenUnboxing,
  });

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const unit = item.item.item_units.length ? item.item.item_units[0].unit : 'Кол-во';

  const handleSubmitForm = useCallback(
    async (values, actions) => {
      try {
        if (editExpirationDate?.id) {
          await api.patch(`/parlorstore-expiration-date/${editExpirationDate.id}/`, values);
        } else {
          await api.post(`/parlorstore-expiration-date/`, { ...values, parlor_store: item.id });
        }
        await refetch();
        actions.resetForm();
        setEditExpirationDate(null);
      } catch (error) {
        handleAxiosErrorSwal(error);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [api, refetch, editExpirationDate],
  );

  const defaultValues = {
    amount: 0,
    end_date: '',
  };

  const today = new Date().toISOString().split('T')[0];

  const formattedDate = (value) => {
    const date = new Date(value);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
      date.getDate(),
    ).padStart(2, '0')}`;
  };

  const validationSchema = Yup.object().shape({
    end_date: Yup.date().required(t('swal.required')),
    amount: Yup.number().required(t('swal.required')).min(0, t('collection.item_minimum_amount')),
  });

  return (
    <>
      {item.item.is_expiration_date && (
        <Row
          className='m-0'
          style={{ backgroundColor: '#18252d', overflowY: 'visible', borderRadius: '8px' }}
        >
          <Row
            className='d-flex p-1 justify-content-between align-items-center'
            style={{ width: '100%' }}
          >
            <p className='m-0'>партии</p>
            <FontAwesomeIcon
              icon={faList}
              className='cursor-edit'
              onClick={() => setIsOpen(item.id)}
            />

            {item.item.is_unwrap_expiration_date && (
              <FontAwesomeIcon
                icon={faBoxOpen}
                className='cursor-edit'
                onClick={() => setIsOpenUnboxing(item.id)}
              />
            )}
          </Row>
          {/* {item.item.is_expiration_date && (
            <Row className='m-0' style={{ overflowY: 'auto', maxHeight: '60px' }}>
              {item.expirationdates.map((el) => (
                <Col key={el.id}>
                  <p>
                    {moment(el.end_date).format('DD.MM.YYYY')} - {el.transactions[0]?.quantity}
                  </p>
                </Col>
              ))}
            </Row>
          )} */}
          <Unboxing
            isOpenUnboxing={isOpenUnboxing}
            setIsOpenUnboxing={setIsOpenUnboxing}
            item={item}
            data={data}
            refetch={refetch}
          />
          <Modal
            size={'lg'}
            isOpen={isOpen === item.id}
            toggle={() => {
              setIsOpen('');
            }}
          >
            <ModalHeader
              toggle={() => {
                setIsOpen('');
              }}
            >
              <h3>
                {item.item.name} {item.item.attribute?.name}
              </h3>
            </ModalHeader>
            <ModalBody className='d-flex flex-column'>
              <Row>
                <Col xs={8}>
                  <p style={{ fontSize: '20px' }}>
                    Количество:{' '}
                    {item.value *
                      (item?.item.item_units?.length ? item?.item.item_units[0].unit?.value : 1)}
                    {unit?.title ?? 'шт.'}
                  </p>
                  <p style={{ fontSize: '20px' }}>
                    По срокам годности:
                    {/* <FontAwesomeIcon icon={faPlus} className='cursor-add' onClick={() => {}} /> */}
                  </p>
                  {data?.expirationdates.map((el) => (
                    <Col key={el.id}>
                      <Row
                        className='d-flex justify-content-end align-items-center'
                        style={{ gap: '10px' }}
                      >
                        {el.is_opened ? (
                          <FontAwesomeIcon icon={faBoxOpen} className='cursor' />
                        ) : (
                          ''
                        )}
                        <p style={{ fontSize: '16px', margin: 0 }}>
                          {`(${moment(el.created_at).format('DD-MM-YYYY HH:mm')}) `}
                        </p>
                        <p style={{ fontSize: '16px', margin: 0 }}>
                          годен до {moment(el.end_date).format('DD.MM.YYYY')} -{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {el.transactions[0].quantity} {unit?.title}
                          </span>
                        </p>
                        <FontAwesomeIcon
                          size='lg'
                          icon={faEdit}
                          className='cursor-edit'
                          onClick={() => setEditExpirationDate(el)}
                        />
                      </Row>
                    </Col>
                  ))}
                </Col>
                <Col xs={4}>
                  <Formik
                    initialValues={
                      editExpirationDate?.id
                        ? {
                            ...editExpirationDate,
                            end_date: formattedDate(editExpirationDate.end_date),
                            amount: editExpirationDate.transactions[0].quantity,
                          }
                        : defaultValues
                    }
                    onSubmit={handleSubmitForm}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                  >
                    {({ handleSubmit, isSubmitting, resetForm }) => (
                      <Form onSubmit={handleSubmit} autoComplete='off'>
                        <Field
                          component={FormikInput}
                          type='date'
                          min={today}
                          name='end_date'
                          placeholder={'Link'}
                          label='Конец срока годности'
                        />
                        <Field
                          name='amount'
                          type='number'
                          component={FormikInput}
                          label={unit?.title}
                        />
                        <Row>
                          <Button type={'submit'} disabled={isSubmitting}>
                            {editExpirationDate ? 'Обновить' : 'Создать'}
                          </Button>
                          <Button
                            type='button'
                            onClick={() => {
                              resetForm();
                              setEditExpirationDate(null); // Reset the form and edit state
                            }}
                          >
                            Отмена
                          </Button>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Row>
      )}
    </>
  );
};
