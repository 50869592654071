import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { selectStyles, isRole, getUploadsURL, toBase64 } from 'helpers';
import lodash from 'lodash';
import classnames from 'classnames';
import Loading from 'components/Loading';
import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  Label,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import ReactTable from 'react-table';
import { ParlorContext, UserContext } from 'src/context.jsx';
import Swal from 'sweetalert2';
import { handleAxiosErrorSwal } from 'helpers/handle.helpers';
import { faPencilAlt, faInfoCircle, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VModal from 'components/VModal';
import Tooltip from 'components/Tooltip';
import TableColumnsToggler from 'components/TableColumnsToggler';
import { useParlorAddStore } from 'hooks/gql.hooks';
import { EditValues } from './EditValues';
import Comments from './Comments';
import { ListAddItems } from './ListAddItems';
import EditCollectionAmount from '../../../components/EditCollectionAmount';
import { SellsHistory } from './SellsHistory';
import { ConsumableHistory } from './ConsumableHistory';
import { isRoleStore } from 'helpers/func.helpers';
import { ChangesList } from '../../Shop/Warehouse/WarehouseModals/ChangesList';
import { useParlorStoreItemsPagination } from '../../../hooks/gql.hooks';
import PaginationComponent from '../../Shop/PaginationComponent';
import { IconButton } from 'components/IconButtonComponent';
import ParlorStoreValues from '../ParlorStoreValues';
import { gql, useQuery } from '@apollo/client';
import i18next from 'i18next';
import { ExpirationDate } from './ExpirationDate';
import AddToActive from './AddToActive';
import { CustomCheckBox } from 'components/CustomCheckBox';
import { ItemRating } from '../../Shop/Item/ItemRating';

export const getLastMonthSell = (sells) => {
  return sells?.filter((el) => {
    const date = new Date();
    return new Date(el.details.created_at) > new Date(date.setMonth(date.getMonth() - 1));
  });
};

export const getUnitsFromValue = (value, item) => {
  if (item.item_units.length) {
    return parseFloat(value * item.item_units[0].unit.value).toFixed(2);
  }
  return value;
};

export const getValueFromUnits = (value, item) => {
  if (item.item_units.length) {
    return parseFloat(value / item.item_units[0].unit.value).toFixed(4);
  }
  return value;
};

const PARLOR_DATA = () => gql`
  query parlorData($parlor: Int!) {
    parlor: core_parlor_by_pk(id: $parlor) {
      is_inventory_period
    }
  }
`;

export default React.memo(() => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { user, api } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const pageSize = 20;
  const [activeTab, setActiveTab] = useState('items');
  const [isLoading, setIsLoading] = useState(false);
  const { parlor } = useContext(ParlorContext);
  const { data: parlorData, refetch: refetchParlorData } = useQuery(PARLOR_DATA(), {
    variables: { parlor: parlor },
  });

  const [loadCategories, categories, subCategories] = useParlorAddStore(parlor);

  const [categoryFilter, setCategoryFilter] = useState({ value: null, label: 'Все категории' });
  const [subCategoryFilter, setSubCategoryFilter] = useState({
    value: null,
    label: 'Все подкатегории',
  });
  const [commentsFilter, setCommentsFilter] = useState(false);
  const [sellsFilter, setSellsFilter] = useState(false);
  // const [ordersFilter, setOrdersFilter] = useState(false);
  const [fullSearchFilter, setFullSearchFilter] = useState(false);
  const [propositionsFilter, setPropositionsFilter] = useState(false);
  const [payFullPointsFilter, setPayFullPointsFilter] = useState(false);
  const [payHalfPointsFilter, setPayHalfPointsFilter] = useState(false);
  const [officialFilter, setOfficialFilter] = useState(false);
  const [unOfficialFilter, setUnOfficialFilter] = useState(false);

  const [sorted, setSorted] = useState();

  const categoriesFilter = useMemo(() => {
    if (categoryFilter?.value) {
      return [categoryFilter.value].concat(
        subCategories.filter((el) => el.parent === categoryFilter.value).map((el) => el.value),
      );
    }
    if (subCategoryFilter?.value) {
      return [subCategoryFilter.value];
    }
  }, [categoryFilter, subCategoryFilter]);

  const { items, total, loading, error, refetch, itemsTotal } = useParlorStoreItemsPagination(
    setPage,
    activeTab,
    parlor,
    categories,
    pageSize * page,
    pageSize,
    sorted,
    payHalfPointsFilter,
    payFullPointsFilter,
    categoriesFilter,
    commentsFilter,
    search,
    fullSearchFilter,
    sellsFilter,
    propositionsFilter,
    officialFilter,
    unOfficialFilter,
  );
  // const {
  //   items: a,
  //   loading: b,
  //   error: c,
  //   refetch: refetchData,
  // } = useParlorStore(fullSearchFilter, parlor, categories);

  useEffect(() => {
    loadCategories();
  }, []);

  const getVariantsItem = () =>
    lodash.cloneDeep(items).map((row) => {
      row.variants = items?.filter((el) => el.item.parent_id === row.item.id);
      return row;
    });
  const storeItems = items ? getVariantsItem() : [];
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isOpenValuesChart, setIsOpenValuesChart] = useState(false);
  const toggleValues = () => setIsOpenValuesChart(!isOpenValuesChart);

  const addImage = React.useCallback(
    (id) => {
      Swal.fire({
        title: 'Добавить фото',
        input: 'file',
        showCancelButton: true,
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Добавить',
        inputValidator: (value) => {
          if (!value) {
            return 'Добавить';
          }
        },
        showLoaderOnConfirm: true,
        preConfirm(inputValue) {
          return toBase64(inputValue).then((image) => {
            return api
              .post(`/item-images/`, { item: id, image })
              .then((res) => res)
              .catch(handleAxiosErrorSwal);
          });
        },
      }).then((result) => {
        if (result.value) {
          Swal.fire('Добавлено!', '', 'success');
        }
      });
    },
    [api],
  );
  const editDiscription = React.useCallback(
    (item) => {
      Swal.fire({
        title: 'Добавить описание товара',
        input: 'textarea',
        showCancelButton: true,
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Добавить',
        inputValue: item.description,
        showLoaderOnConfirm: true,
        preConfirm(description) {
          return api
            .patch(`/items/${item.id}/`, { description })
            .then((res) => res)
            .catch(handleAxiosErrorSwal);
        },
      }).then((result) => {
        if (result.value) {
          Swal.fire('Добавлено!', '', 'success');
        }
      });
    },
    [api],
  );

  const [expandedAttributesRows, setExpandedAttributesRows] = useState({});
  const [expandedItemsRows, setExpandedItemsRows] = useState({});

  const getIsMainItem = (item) => {
    if (item.variants?.length || item.item.attribute?.name === 'main') return true;
  };

  const changeStatusTypePaymentItem = async (item, type, status) => {
    if (!isRole(['owner'], user)) return;

    await api
      .patch(`/items/${item.item.id}/`, { [type]: status })
      .then(() => {
        refetch();
      })
      .catch(handleAxiosErrorSwal);
  };

  const columns = (user) => [
    {
      Header: t('item_table.category'),
      name: 'category',
      id: 'category',
      accessor: 'item.category',
      Cell: (row) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            {row.original?.item && (
              <>
                <Tooltip
                  id={`info-${row.original.id}-${row.index}`}
                  placement='left'
                  tooltipContent={
                    <div>
                      <img src={`${row.original.item?.img}`} />
                      <p>{row.original.item.description}</p>
                    </div>
                  }
                >
                  <FontAwesomeIcon
                    id={`info-${row.original.id}-${row.index}`}
                    color='green'
                    size='lg'
                    cursor='pointer'
                    icon={faInfoCircle}
                  />
                </Tooltip>
                <p>{row.value?.label}</p>
              </>
            )}
          </div>
        );
      },
      width: 160,
    },
    {
      Header: t('item_table.sub_category'),
      id: 'sub_category',
      name: 'subCategory',
      accessor: 'item.sub_category',
      Cell: (row) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            {row.original?.item && (
              <>
                <Tooltip
                  id={`info-${row.original.id}-${row.index}`}
                  placement='left'
                  tooltipContent={
                    <div>
                      <img src={`${row.original.item?.img}`} />
                      <p>{row.original.item.description}</p>
                    </div>
                  }
                >
                  <FontAwesomeIcon
                    id={`info-${row.original.id}-${row.index}`}
                    color='green'
                    size='lg'
                    cursor='pointer'
                    icon={faInfoCircle}
                  />
                </Tooltip>
                <p>{row.value.label}</p>
              </>
            )}
          </div>
        );
      },
      width: 160,
    },
    {
      Header: t('item_table.product_name'),
      name: 'name',
      id: 'name',
      accessor: 'item.name',
      Cell: (row) => {
        return (
          <>
            <a href={`/crm/store/items/${row.original.id}`} target={'_blank'}>
              <FontAwesomeIcon id={``} size='lg' cursor='pointer' icon={faInfoCircle} />
            </a>
            {row.original.item.name}
            {(!getIsMainItem(row.original) && row.original.item?.attribute?.name) || ''}
          </>
        );
      },
    },
    {
      Header: t('tables.product_attributes'),
      accessor: 'attributes',
      name: 'attributes',
      Cell: (row) => (
        <div className='position-relative'>
          {row.original?.attributes?.length ? (
            <Tooltip
              id={'product-attribute_' + row.original.id}
              autohide={false}
              delay={{ show: 100, hide: 250 }}
              placement='bottom'
              tooltipContent={row.original.attributes.map((attr) => {
                return (
                  <p key={attr.id}>
                    {attr.item}
                    &nbsp;
                    {attr.item.attribute?.name || ''} - {attr.item.sell_price}
                  </p>
                );
              })}
            >
              <FontAwesomeIcon
                className='product-detail-tooltip product-detail-tooltip--variant_2'
                icon={faInfoCircle}
                size='xl'
                id={'product-attribute_' + row.original.id}
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: t('item_table.photo'),
      name: 'image',
      accessor: 'item.itemimages',
      Cell: (row) => {
        return (
          <>
            {!getIsMainItem(row.original) && (
              <>
                <div>
                  {!!row.original.item.itemimages.length && (
                    <img
                      style={{ width: '120px', maxHeight: '120px' }}
                      src={getUploadsURL(row.original.item.itemimages[0].image)}
                      alt=''
                    />
                  )}
                </div>
                <ItemRating
                  itemId={row.original.item.id}
                  rating={row.original.item?.rating}
                  refetch={refetchParlorData}
                />
              </>
            )}
          </>
        );
      },
      width: 150,
      className: 'text-center',
    },
    {
      Header: 'Активы',
      name: 'user_gadget',
      accessor: 'user_gadget',
      Cell: (row) => {
        return (
          <div>
            {row.original?.accounts_usergadgets?.length > 0 ? (
              <div>
                <CustomCheckBox label={`Связь #`} checked={true} />
              </div>
            ) : (
              <AddToActive
                data={row}
                parlor={parlor}
                price={row.original.value * row.original.item.purchase_price}
                count={row.original.value}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: t('item_table.quantity'),
      name: 'value',
      id: 'value',
      accessor: 'value',
      Cell: (row) => {
        return (
          !getIsMainItem(row.original) && (
            <Col>
              <EditValues
                refetchData={() => refetch()}
                currentValue={getUnitsFromValue(row.original.value, row.original.item)}
                item={row}
                fieldName={'value'}
                propositionFieldName={'proposition_value'}
                isHideFieldName={''}
                isHideButton={false}
                title={'количество'}
                storeId={row.original.parlor_id}
                units={row.original.item.item_units[0]?.unit.title}
                getUnitsFromValue={getUnitsFromValue}
                getValueFromUnits={getValueFromUnits}
              />
              <ExpirationDate item={row.original} />
            </Col>
          )
        );
      },
      width: 170,
      className: 'text-center',
    },
    {
      Header: t('item_table.average_balances'),
      name: 'average_remains',
      accessor: 'average_remains',
      id: 'average_remains',
      width: 100,
      Cell: (row) => {
        return <p>{row.original.average_remains}</p>;
      },
    },
    {
      Header: t('item_table.pay_full_with_points'),
      name: 'pay_full_points',
      accessor: 'pay_full_points',
      id: 'pay_full_points',
      width: 200,
      Cell: (row) => {
        if (row.original.item?.attribute?.name === 'main') return <></>;
        return (
          <td>
            {row.original.item.pay_full_points ? (
              <IconButton
                icon={faCheck}
                size='lg'
                className={'cursor-add'}
                style={{ margin: '0 5px' }}
                onClick={() => changeStatusTypePaymentItem(row.original, 'pay_full_points', false)}
              />
            ) : (
              <IconButton
                icon={faXmark}
                size='lg'
                onClick={() => changeStatusTypePaymentItem(row.original, 'pay_full_points', true)}
                className={'cursor-add'}
                style={{ color: 'red', margin: '0 5px' }}
              />
            )}
          </td>
        );
      },
    },
    {
      Header: t('item_table.pay_half_with_points'),
      name: 'pay_half_points',
      accessor: 'pay_half_points',
      id: 'pay_half_points',
      Cell: (row) => {
        if (row.original.item?.attribute?.name === 'main') return <></>;
        return (
          <td>
            {row.original.item.pay_half_points ? (
              <IconButton
                onClick={() => changeStatusTypePaymentItem(row.original, 'pay_half_points', false)}
                icon={faCheck}
                size='lg'
                className='cursor-add'
                style={{ margin: '0 5px' }}
              />
            ) : (
              <IconButton
                onClick={() => changeStatusTypePaymentItem(row.original, 'pay_half_points', true)}
                icon={faXmark}
                size='lg'
                className='cursor-add'
                style={{ color: 'red', margin: '0 5px' }}
              />
            )}
          </td>
        );
      },
      width: 200,
    },
    {
      Header: 'Кол-во в наборе',
      name: 'amountInCollection',
      accessor: 'amountInCollection',
      width: 140,
      Cell: (row) => {
        return (
          <div>
            <span>{row.original.amountInCollection}</span>
            {!isRoleStore(['administrator', 'master'], user) && (
              <>
                <span> / </span>
                <EditCollectionAmount item={row.original} callback={() => refetchData()} />
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: t('item_table.quantity_for_production'),
      name: 'amountInItem',
      accessor: 'amountInItem',
      width: 200,
      Cell: (row) => {
        return (
          <div>
            <span>{row.original.amountInItem}</span>
          </div>
        );
      },
    },
    {
      Header: t('item_table.expected_in_stock'),
      name: 'expected',
      accessor: (d) => {
        return (
          !getIsMainItem(d) &&
          getUnitsFromValue(
            d.item?.store_orderitems
              ?.filter((el) => el.details.status === 500)
              .reduce((acc, el) => (acc += el.value), 0),
            d.item,
          )
        );
      },
      id: 'expected',
      className: 'text-center',
      width: 160,
    },
    {
      Header: t('item_table.ordered'),
      name: 'ordered',
      accessor: (d) => {
        return (
          !getIsMainItem(d) &&
          getUnitsFromValue(
            d.item?.store_orderitems
              ?.filter((el) => el.details.status === 100)
              .reduce((acc, el) => (acc += el.value), 0),
            d.item,
          )
        );
      },
      id: 'ordered',
      className: 'text-center',
      width: 100,
    },
    {
      Header: t('item_table.unit_of_measurement'),
      name: 'units',
      accessor: '',
      Cell: (row) => {
        return row.original.item.units;
      },
      width: 150,
      className: 'text-center',
    },
    {
      Header: t('item_table.comment'),
      name: 'comments',
      accessor: 'store_parlorstorecomments.length',
      Cell: (row) => !getIsMainItem(row.original) && <Comments item={row} />,
      width: 150,
      className: 'text-center',
    },
    ...(isRole(['owner', 'economist'], user) ||
    isRoleStore('moderators', user) ||
    isRoleStore('storekeepers', user)
      ? [
          {
            Header: t('item_table.purchase_price_opt'),
            name: 'purchase_price',
            accessor: (d) => !getIsMainItem(d) && d.item.purchase_price.toFixed(2),
            width: 150,
            id: 'purchase_price',
            className: 'text-center',
          },
        ]
      : []),
    {
      Header: t('item_table.min_sell_price_parlor'),
      name: 'sell_price_extra',
      accessor: (d) => {
        return !getIsMainItem(d) && d.item.sell_price_extra.toFixed(3);
      },
      width: 130,
      id: 'sell_price_extra',
      className: 'text-center',
    },
    {
      Header: t('item_table.base_sell_price_parlor'),
      name: 'sell_price',
      accessor: (d) => !getIsMainItem(d) && d.item.sell_price.toFixed(2),
      width: 130,
      id: 'sell_price',
      className: 'text-center',
    },
    {
      Header: t('item_table.price_in_shop'),
      name: 'provided_price',
      accessor: (d) => !getIsMainItem(d) && d.item.provided_price.toFixed(2),
      id: 'provided_price',
      width: 150,
      className: 'text-center',
    },
    ...(isRole(['owner', 'economist'], user) ||
    isRoleStore('moderators', user) ||
    isRoleStore('storekeepers', user)
      ? [
          {
            Header: t('item_table.price_online'),
            name: 'shop_price',
            accessor: (d) => !getIsMainItem(d) && d.item.shop_price.toFixed(2),
            width: 100,
            id: 'shop_price',
            className: 'text-center',
          },
        ]
      : []),

    ...(!isRole(['administrator', 'master'], user)
      ? [
          {
            Header: 'Итого',
            name: 'total',
            id: 'total',
            accessor: (d) => d.value * d.item.purchase_price,
            Cell: (row) =>
              !getIsMainItem(row.original) &&
              (row.original.value * row.original.item.purchase_price).toFixed(2),
            width: 100,
            className: 'text-center',
          },
        ]
      : []),
    {
      Header: t('item_table.description'),
      name: 'description',
      accessor: 'item.description',
      Cell: (row) => (
        <Row>
          <Col xs={1}>
            {' '}
            {!isRole(['administrator', 'master'], user) && (
              <FontAwesomeIcon
                icon={faPencilAlt}
                className='cursor-edit'
                onClick={() => editDiscription(row.original.item)}
              />
            )}
          </Col>
          <Col xs={11}>
            <p style={{ overflowY: 'auto', maxHeight: '80px' }}>{row.value}</p>
          </Col>
        </Row>
      ),
      width: 300,
      className: 'text-center',
    },

    // {Header: "Итого", accessor: "", Cell: row => (row.original.value * row.original.item.sell_price).toFixed(2) , width: 100, className: "text-center"},
    {
      Header: t('item_table.avaible_hidden'),
      name: 'available',
      accessor: (d) => (d.item.available ? 'Доступен' : 'Скрыт'),
      id: 'available',
      className: 'text-center',
    },
    {
      Header: t('item_table.additions_history'),
      name: 'history',
      accessor: 'item.store_orderitems',
      Cell: (row) => !getIsMainItem(row.original) && <ListAddItems item={row.original.item} />,
      id: 'history',
      width: 170,
      className: 'text-center',
    },
    {
      Header: t('item_table.edits_history'),
      name: 'history_changes',
      accessor: 'propositions_history',
      Cell: (row) => {
        return !getIsMainItem(row.original) && <ChangesList item={row.original} />;
      },
      width: 170,
      id: 'history_changes',
      className: 'text-center',
    },
    {
      Header: t('item_table.selling_history'),
      name: 'history_no_payment',
      accessor: 'item.marketable',
      Cell: (row) => {
        return !getIsMainItem(row.original) && <SellsHistory item={row.original} />;
      },
      width: 170,
      id: 'history_no_payment',
      className: 'text-center',
    },
    {
      Header: t('item_table.history_write_offs'),
      name: 'consumables',
      accessor: 'item.consumables',
      Cell: (row) => {
        return (
          !getIsMainItem(row.original) && <ConsumableHistory item={row.original} parlor={parlor} />
        );
      },
      width: 170,
      id: 'consumables',
      className: 'text-center',
    },
  ];
  const exludedColumns = ['attributes', 'amountInCollection'];
  const exludedColumnsComponents = ['amountInCollection', 'amountInItem'];
  const attributesColumns = columns(user).filter((el) => !exludedColumns.includes(el.name));
  const productsColumns = columns(user).filter((el) => el.name !== 'amountInCollection');
  const componentsColumns = columns(user).filter(
    (el) => !exludedColumnsComponents.includes(el.name),
  );

  if (error) {
    console.log(error);
    return null;
  }

  const sorteredColumns = (column) => {
    return [
      { id: 'value', sort: `value: ${column[0]?.desc ? 'desc' : 'asc'}` },
      {
        id: 'pay_half_points',
        sort: `store_item: {pay_half_points: ${column[0]?.desc ? 'desc' : 'asc'}}`,
      },
      {
        id: 'pay_full_points',
        sort: `store_item: {pay_full_points: ${column[0]?.desc ? 'desc' : 'asc'}}`,
      },
      {
        id: 'name',
        sort: `store_item: {name: ${column[0]?.desc ? 'desc' : 'asc'}}`,
      },
      {
        id: 'category',
        sort: `store_item: {store_category: {name: ${column[0]?.desc ? 'desc' : 'asc'}}}`,
      },
      {
        id: 'sub_category',
        sort: `store_item: {store_category: {name: ${column[0]?.desc ? 'desc' : 'asc'}}}`,
      },
    ].find((el) => el.id === column[0].id)?.sort;
  };

  const getDataTable = () => {
    const newData = storeItems
      .sort((a, b) => {
        return !sorted
          ? b.item.marketable?.aggregate.count - a.item.marketable?.aggregate.count
          : 0;
      })
      .filter((el) => !el.item.parent_id)
      .filter((f) => f.accounts_usergadgets?.length === 0)
      .map((el) => {
        const proposition_value = el.propositions_history
          .filter((el) => el.status === 'new')
          .find((proposition) => proposition.value !== null);
        return {
          ...el,
          proposition_value,
        };
      });
    return newData;
  };
  const getCollectionItemsData = (row) => {
    const { store_collectionitems } = row.original.item;

    if (store_collectionitems) {
      const collectionItems = store_collectionitems.map((item) => {
        const itemData = items.find((el) => el.item.id === item.item.id);

        return {
          ...itemData,
          collectionItemId: item.id,
          amountInCollection: item.amountInCollection,
        };
      });

      const fullTableData = getDataTable();
      const filteredItems = collectionItems.filter((item) => item && item.item);
      const itemsWithAttributes = filteredItems.map((item) => {
        const itemData = fullTableData.find((el) => el.item.id === item.item.id);

        if (itemData) {
          return {
            ...itemData,
            collectionItemId: item.id,
            amountInCollection: item.amountInCollection,
          };
        }

        return item;
      });

      return itemsWithAttributes;
    }

    return [];
  };
  const getComponentsItemsData = (row) => {
    const { store_componentitems } = row.original.item;

    if (store_componentitems) {
      const collectionItems = store_componentitems.map((item) => {
        const itemData = items.find((el) => el.item.id === item.item_id);

        return {
          ...itemData,
          producedItemId: item.id,
          amountInItem: item.amountInItem,
          is_component: true,
        };
      });

      const fullTableData = getDataTable();
      const filteredItems = collectionItems.filter((item) => item && item.item);
      const itemsWithAttributes = filteredItems.map((item) => {
        const itemData = fullTableData.find((el) => el.item.id === item.item.id);

        if (itemData) {
          return {
            ...itemData,
            producedItemId: item.producedItemId,
            amountInItem: item.amountInItem,
            is_component: true,
          };
        }

        return item;
      });

      return itemsWithAttributes;
    }

    return [];
  };
  const setIsInventory = useCallback(async () => {
    setIsLoading(true);
    await api.patch(`/parlor/${parlor}/`, {
      is_inventory_period: !parlorData?.parlor?.is_inventory_period,
    });
    refetchParlorData();
    setIsLoading(false);
    return;
  }, [parlor, parlorData]);

  const debouncedOnChange = lodash.debounce(setSearch, 1000);

  return (
    <Card>
      <CardBody>
        {isOpenValuesChart && <ParlorStoreValues parlor={parlor} />}
        <Row>
          <Col lg={12} className='d-flex justify-content-between'>
            <p style={{ fontWeight: 'bold' }}>
              {t('item_table.storage_price')}:{' '}
              {itemsTotal?.length
                ? itemsTotal
                    .filter((item) => item.item.variant?.name !== 'main')
                    .filter((f) => f.accounts_usergadgets?.length === 0)
                    .reduce((a, b) => {
                      return b.value > 0 ? a + b.value * b.item.provided_price : a + 0;
                    }, 0.0)
                    .toFixed(2)
                : '0'}
            </p>
            <p style={{ fontWeight: 'bold' }}>
              {t('item_table.quantity_items')}:{' '}
              {itemsTotal?.length
                ? itemsTotal
                    .filter((item) => item.item.variant?.name !== 'main')
                    .reduce((a, b) => {
                      return b.value > 0 ? a + b.value : a + 0;
                    }, 0.0)
                    .toFixed(2)
                : '0'}
            </p>
            {(isRole(['owner', 'director'], user) || isRoleStore(['moderators'], user)) &&
              (isOpenValuesChart ? (
                <Button className='m-0 p-2' onClick={() => toggleValues()}>
                  Скрыть графики
                </Button>
              ) : (
                <Button className='m-0 p-2' onClick={() => toggleValues()}>
                  {t('item_table.show_graph')}
                </Button>
              ))}
            <h6
              style={{
                border: '1px solid coral',
                padding: '6px',
                borderRadius: '5px',
                whiteSpace: '2px',
              }}
            >
              <NavItem style={{ marginBottom: '5px' }}>
                <a
                  style={{ color: 'red', fontWeight: 'bold' }}
                  target='_blank'
                  href='https://t.me/+4HatGY1HO3MzMDBi'
                >
                  {t('item_table.storage_help')}
                </a>
              </NavItem>
            </h6>
          </Col>

          <Col md={4}>
            <div style={{ display: 'flex', 'align-items': 'center', marginBottom: '7px' }}>
              <div style={{ marginLeft: '5px' }}>
                <p style={{ margin: '0px', paddingRight: '5px' }}>
                  {t('item_table.quantity_items_info')}
                </p>
              </div>
              <Tooltip
                id='info'
                placement='bottom'
                tooltipContent={
                  <>
                    {' '}
                    <p className='text-center'>Количество</p>
                    <p>Нет в наличии меньше или равно 0</p>
                    <p>Минимальное от 1 до 9</p>
                    <p>Нормальное 10 до 19</p>
                    <p>Достаточное больше или равно 20</p>
                  </>
                }
              >
                <FontAwesomeIcon
                  id='info'
                  color='green'
                  size='lg'
                  cursor='pointer'
                  icon={faInfoCircle}
                />
              </Tooltip>
            </div>
          </Col>
          <Row style={{ marginBottom: '15px', marginTop: '15px' }}>
            <Col xs={12} lg={3}>
              <h6
                style={{
                  border: '1px solid coral',
                  padding: '6px',
                  borderRadius: '5px',
                  whiteSpace: '2px',
                }}
              >
                <p style={{ fontWeight: '600', color: '#ff4040', textAlign: 'center' }}>
                  ВНИМАНИЕ!{' '}
                </p>
                Если срок годности какого-либо товара подходит к концу- СООБЩИТЕ ОБ ЭТОМ
                УПРАВЛЯЮЩЕМУ! Вся ответсвенность за просроченный расходник лежит на администраторе и
                управляющем!
              </h6>
            </Col>
            {isRole('administrator', user) && (
              <Col xs={12} lg={3}>
                <h6
                  style={{
                    border: '1px solid coral',
                    padding: '6px',
                    borderRadius: '5px',
                    whiteSpace: '2px',
                  }}
                >
                  <p style={{ fontWeight: '600', color: '#ff4040', textAlign: 'center' }}>
                    ВНИМАНИЕ АДМИНИСТРАТОРАМ!{' '}
                  </p>
                  Если товара нет в наличии, просьба добавить через поле "добавление товара на
                  склад"!
                </h6>
              </Col>
            )}
            {isRole('master', user) && (
              <Col xs={12} lg={3}>
                <h6
                  style={{
                    border: '1px solid coral',
                    padding: '6px',
                    borderRadius: '5px',
                    whiteSpace: '2px',
                  }}
                >
                  <p style={{ fontWeight: '600', color: '#ff4040', textAlign: 'center' }}>
                    ВНИМАНИЕ МАСТЕРАМ!{' '}
                  </p>
                  Мастера имеют право учавствовать в расходе заказа на студию, топовые мастера имеют
                  право индивидуального заказа
                </h6>
              </Col>
            )}
            {!isRole(['master', 'administrator'], user) && (
              <Col xs={12} lg={3}>
                <div>
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '15px',
                      color: 'orange',
                      cursor: 'pointer',
                    }}
                    onClick={toggle}
                  >
                    ИНФОРМАЦИЯ добавления описание и фото!
                  </p>
                  <a
                    href={`/api/parlor_warehouse/${parlor}/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Открыть страницу печати
                  </a>
                </div>
                {isRole(['owner'], user) && (
                  <Button
                    disabled={isLoading}
                    onClick={() => setIsInventory()}
                    style={{ padding: '8px' }}
                    color={parlorData?.parlor?.is_inventory_period ? 'danger' : ''}
                  >
                    {parlorData?.parlor?.is_inventory_period
                      ? 'Выключить инвентаризацию'
                      : 'Включить инвентаризацию'}
                  </Button>
                )}
              </Col>
            )}
            <Col xs={12} lg={2}>
              <div
                style={{ display: 'flex', gap: '20px', marginBottom: '15px', marginTop: '15px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgb(56, 142, 60, 0.5)',
                    padding: '3px',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    color: 'white',
                    borderRadius: '8px',
                    fontStyle: 'italic',
                    width: '100%',
                  }}
                >
                  {t('item_table.best_sellers')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgb(239, 83, 80, 0.5)',
                    padding: '3px',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    color: 'white',
                    borderRadius: '8px',
                    fontStyle: 'italic',
                  }}
                >
                  {t('item_table.runout_best_sellers')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#8080806b',
                    padding: '3px',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    color: 'white',
                    borderRadius: '8px',
                    fontStyle: 'italic',
                  }}
                >
                  {t('item_table.general_items_variants')}
                </div>
              </div>
            </Col>
            <Collapse isOpen={isOpen}>
              <Card>
                <CardBody>
                  <h1>Пример Описания!!!</h1>
                  <ul style={{ fontSize: '15px' }}>
                    <li>
                      От мощности и стабильности блока питания зависит качество и скорость нанесения
                      татуировки.
                    </li>
                    <li>
                      Почти все блоки питания производят в Китае. Большинство из них сделаны
                      неправильно.
                    </li>
                    <li>
                      {' '}
                      Обусловлено это тем, что в них используется неэффективное охлаждение и (или)
                      непродуманная схема. В основном это одна и та же схема, но разная по дизайну
                      коробка блока.
                    </li>
                    <li>
                      {' '}
                      Непродуманность этих схем заключается в том, что через 5-30 минут
                      перегреваются некоторые детали и машинка начинает работать неровно и мощность
                      ее падает!
                    </li>
                    <li>
                      {' '}
                      Нагрев деталей не всегда сопровождается нагревом корпуса, так что пока не
                      отнесешь на ремонт специалисту - не поймешь в чем дело.
                    </li>
                    <li>
                      {' '}
                      Используйте качественные брендовые блоки питания, желательно на 3 ампера. И
                      желательно ИМПУЛЬСНЫЕ - у них КПД выше, чем у АНАЛОГОВЫХ. Из-за этого они и не
                      греются. Аналоговые блоки тоже хорошие, но им нужно хорошее охлаждение, а это
                      прибавка в весе. Китайские блоки в ценовой категории до 100 долларов обычно
                      изготавливаются по АНАЛОГОВОЙ схеме и не имеют качественного охлаждения и
                      машинка от них работает плохо.
                    </li>
                    <li>
                      {' '}
                      Также в блоках могут быть плохие гнезда для джеков. Из-за этого могут быть
                      перебои и падение мощности.
                    </li>
                    <li>
                      {' '}
                      Если хотите хороший блок - заказывайте, я гарантирую, что с блоком у Вас не
                      возникнет проблем. Исключение составляют проблемы с прыжками напряжения в
                      сети. Советую приобрести стабилизатор, или бесперебойник - это поможет блоку
                      жить дольше.
                    </li>
                    <li>
                      {' '}
                      На блоки распространяется гарантия - 1 год бесплатного ремонта и пожизненный
                      сервис (платный). Так, что если ваш блок сгорит от действия непреодолимой силы
                      - приносите - подчиним всегда.
                    </li>
                    <li>
                      {' '}
                      Параметры - 25 ватт, 3 ампера, от 1,5 до 14 вольт. Плавная 10-ти оборотная
                      регулировка вольтажа, мощный магнит с мягкой резиновой посадкой, чтобы не
                      царапать поверхности. Корпус из аэрокосмического алюминия.
                    </li>
                  </ul>
                  <h1>Пример ФОТО</h1>
                  <img src='https://i.ibb.co/tshWKHy/image.png' alt='photoExampel' />
                </CardBody>
              </Card>
            </Collapse>
          </Row>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Select
                classNamePrefix='vean'
                styles={selectStyles()}
                onChange={(option) => setCategoryFilter(option)}
                options={categories}
                defaultValue={categoryFilter}
                placeholder='Выберите категорию'
              />
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Select
                classNamePrefix='vean'
                styles={selectStyles()}
                onChange={(option) => setSubCategoryFilter(option)}
                options={subCategories}
                defaultValue={subCategoryFilter}
                placeholder='Выберите подкатегорию'
              />
            </FormGroup>
          </Col>
          <Col lg={3}>
            <Input
              type='text'
              onChange={(e) => debouncedOnChange(e.target.value)}
              placeholder='Поиск...'
              style={{ marginBottom: '10px' }}
            />
          </Col>
          <Col lg={2} style={{ marginLeft: '10px' }}>
            <Label check>
              <Input
                type='checkbox'
                checked={fullSearchFilter}
                onChange={() => setFullSearchFilter(!fullSearchFilter)}
              />
              <span className='form-check-sign'>
                <span className='check' /> Показать все товары? <small>(show all items?)</small>
              </span>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={commentsFilter}
                onChange={() => setCommentsFilter(!commentsFilter)}
              />
              <span className='form-check-sign'>
                <span className='check' /> {t('item_table.show_with_comments')}?{' '}
                <small>(show only items with comments?)</small>
              </span>
            </Label>
          </Col>
          <Col lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={propositionsFilter}
                onChange={() => setPropositionsFilter(!propositionsFilter)}
              />
              <span className='form-check-sign'>
                <span className='check' /> {t('item_table.show_with_apps')}?{' '}
                <small>(show items with prepositions?)</small>
              </span>
            </Label>
          </Col>
          <Col lg={2}>
            <Label check>
              <Input
                type='checkbox'
                checked={payFullPointsFilter}
                onChange={() => {
                  setPayFullPointsFilter(!payFullPointsFilter);
                }}
              />
              <span className='form-check-sign'>
                <span className='check' />
                {t('item_table.show_items_buy_with_points')}?{' '}
              </span>
            </Label>
          </Col>
          <Col lg={2}>
            <Label check>
              <Input
                type='checkbox'
                checked={payHalfPointsFilter}
                onChange={() => {
                  setPayHalfPointsFilter(!payHalfPointsFilter);
                }}
              />
              <span className='form-check-sign'>
                <span className='check' />
                {t('item_table.show_items_buy_half_points')}?{' '}
              </span>
            </Label>
          </Col>
          {/* <Col lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={ordersFilter}
                onChange={() => setOrdersFilter(!ordersFilter)}
              />
              <span className='form-check-sign'>
                <span className='check' /> Показать наиболее заказываемые?{' '}
                <small>(show only items with comments?)</small>
              </span>
            </Label>
          </Col> */}
          <Col xs={12} lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={sellsFilter}
                onChange={() => setSellsFilter(!sellsFilter)}
              />
              <span className='form-check-sign'>
                <span className='check' /> {t('item_table.show_best_sellers')}?{' '}
                <small>(show only items with comments?)</small>
              </span>
            </Label>
          </Col>
          <Col xs={12} lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={officialFilter}
                onChange={() => {
                  if (unOfficialFilter) {
                    setUnOfficialFilter(false);
                  }
                  setOfficialFilter(!officialFilter);
                }}
              />
              <span className='form-check-sign'>
                <span className='check' /> {t('item_table.show_official_items')}?{' '}
                <small>(show only official items?)</small>
              </span>
            </Label>
          </Col>
          <Col xs={12} lg={3}>
            <Label check>
              <Input
                type='checkbox'
                checked={unOfficialFilter}
                onChange={() => {
                  if (officialFilter) {
                    setOfficialFilter(false);
                  }
                  setUnOfficialFilter(!unOfficialFilter);
                }}
              />
              <span className='form-check-sign'>
                <span className='check' /> {t('item_table.show_non_official_items')}?{' '}
                <small>(show only nonofficial items?)</small>
              </span>
            </Label>
          </Col>
          <Col xs={12} lg={3}>
            <Select
              classNamePrefix='vean'
              styles={selectStyles()}
              onChange={(option) => setSorted(option.value)}
              options={[
                { value: '', label: 'Без сортировки по оценке' },
                {
                  value: `store_item: {rating: asc}`,
                  label: 'Сортировать от меньшей к большей',
                },
                {
                  value: `store_item: {rating: desc}`,
                  label: 'Сортировать от большей к меньшей',
                },
              ]}
              defaultValue={{ value: '', label: 'Без сортировки по оценке' }}
              placeholder='Сортировать по оценке'
            />
          </Col>
        </Row>
        <Nav tabs className='mb-4'>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'items' })}
              onClick={() => {
                setActiveTab('items');
              }}
            >
              {t('item_table.items')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'collections' })}
              onClick={() => {
                setActiveTab('collections');
              }}
            >
              {t('item_table.sets')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'produced-items' })}
              onClick={() => {
                setActiveTab('produced-items');
              }}
            >
              {t('item_table.manufactured_items')}
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === 'collections' ? (
          <TableColumnsToggler
            storageKey={`vean-store-table-collections`}
            columns={productsColumns}
            render={(filterColumns) => (
              <ReactTable
                columns={filterColumns(productsColumns)}
                expanded={expandedItemsRows}
                onExpandedChange={(expanded, ind) => {
                  if (expandedItemsRows[ind]) {
                    setExpandedItemsRows({});
                  } else {
                    setExpandedItemsRows({ [ind]: {} });
                  }

                  setExpandedAttributesRows();
                }}
                subRowsKey={'expand'}
                data={getDataTable(true)}
                getTdProps={() => ({
                  style: {
                    textOverflow: 'inherit',
                    whiteSpace: 'normal',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                })}
                getTrProps={(state, row) => {
                  if (!row) return {};

                  return {
                    'data-childs': row.original.item?.store_collectionitems?.length || 0,
                  };
                }}
                SubComponent={(row) => (
                  <React.Fragment>
                    <h4 className='pl-3'>Товары</h4>
                    <ReactTable
                      className='ml-3 nested-table'
                      columns={filterColumns(columns(user), ['amountInCollection'])}
                      data={getCollectionItemsData(row)}
                      minRows={0}
                      showPaginationBottom={false}
                      expanded={expandedAttributesRows}
                      onExpandedChange={(expanded) => {
                        setExpandedAttributesRows(expanded);
                      }}
                      subRowsKey={'expand'}
                      getTdProps={() => ({
                        style: {
                          textOverflow: 'inherit',
                          whiteSpace: 'normal',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                        },
                      })}
                      getTrProps={(state, itemRow) => {
                        if (!itemRow) return {};

                        return {
                          'data-childs': itemRow.original?.attributes?.length || 0,
                        };
                      }}
                      SubComponent={(itemRow) =>
                        itemRow.original?.attributes?.length ? (
                          <React.Fragment>
                            <h4 className='pl-3'>Варианты</h4>
                            <ReactTable
                              className='ml-3 nested-table'
                              columns={filterColumns(attributesColumns)}
                              data={itemRow.original.attributes}
                              minRows={0}
                              showPaginationBottom={false}
                              getTdProps={() => ({
                                style: {
                                  textOverflow: 'inherit',
                                  whiteSpace: 'normal',
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                },
                              })}
                            />
                          </React.Fragment>
                        ) : null
                      }
                    />
                    {row.original?.attributes?.length ? (
                      <React.Fragment>
                        <h4 className='pl-3 mt-3'>Атрибуты</h4>
                        <ReactTable
                          className='ml-3 nested-table'
                          columns={filterColumns(attributesColumns)}
                          data={row.original.attributes}
                          minRows={0}
                          showPaginationBottom={false}
                          getTdProps={() => ({
                            style: {
                              textOverflow: 'inherit',
                              whiteSpace: 'normal',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            },
                          })}
                        />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
                minRows={4}
                defaultPageSize={20}
                showPageSizeOptions={false}
                previousText={'Пред. страница'}
                nextText={'След. страница'}
                pageText={'Страница'}
                ofText={'из'}
              />
            )}
          ></TableColumnsToggler>
        ) : null}

        {activeTab === 'items' ? (
          <TableColumnsToggler
            storageKey={`vean-store-table-items`}
            columns={productsColumns}
            render={(filterColumns) => (
              <ReactTable
                columns={filterColumns(productsColumns)}
                expanded={expandedAttributesRows}
                onExpandedChange={(expanded) => {
                  setExpandedAttributesRows(expanded);
                }}
                onSortedChange={(sort) => {
                  const isSorted = sorteredColumns(sort);
                  if (isSorted) {
                    setSorted(sorteredColumns(sort));
                  }
                }}
                SubComponent={(row) =>
                  row.original?.variants?.length ? (
                    <React.Fragment>
                      <h4 className='pl-3'>Варианты</h4>
                      <ReactTable
                        className='ml-3 nested-table'
                        columns={filterColumns(attributesColumns)}
                        data={row.original.variants
                          .filter((attr) =>
                            !fullSearchFilter && isRole('administrator', user)
                              ? attr.value !== 0
                              : true,
                          )
                          .map((el) => {
                            const proposition_value = el.propositions_history
                              .filter((el) => el.status == 'new')
                              .find((proposition) => proposition.value !== null);
                            return {
                              ...el,
                              proposition_value,
                            };
                          })}
                        minRows={0}
                        showPaginationBottom={false}
                        defaultPageSize={row.original?.variants?.length}
                        getTdProps={() => ({
                          style: {
                            textOverflow: 'inherit',
                            whiteSpace: 'normal',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          },
                        })}
                      />
                    </React.Fragment>
                  ) : null
                }
                subRowsKey={'expand'}
                data={getDataTable()}
                getTdProps={() => ({
                  style: {
                    textOverflow: 'inherit',
                    whiteSpace: 'normal',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                })}
                getTrProps={(state, row) => {
                  if (!row) return {};
                  if (
                    row.original.variants.length ||
                    row.original.item.attribute?.name === 'main'
                  ) {
                    return {
                      style: {
                        backgroundColor: '#8080806b',
                      },
                      'data-childs': row.original?.variants?.length,
                    };
                  } else {
                    return {
                      style: row.original.item.marketable.aggregate.count
                        ? row.original.value <= 5
                          ? {
                              backgroundColor: `rgb(239, 83, 80, ${
                                1 - (row.original.value * 100) / 10 / 100
                              })`,
                              color: 'white',
                            }
                          : row.original.average_remains &&
                            row.original.average_remains < row.original.value
                          ? {
                              backgroundColor: `#c2a93cc4`,
                              color: 'white',
                            }
                          : {
                              backgroundColor: `rgb(56, 142, 60, 0.5)`,
                              color: 'white',
                            }
                        : {},
                      'data-childs': row.original?.variants?.length,
                    };
                  }
                }}
                minRows={4}
                defaultPageSize={20}
                showPageSizeOptions={false}
                ofText={'из'}
                PaginationComponent={() => (
                  <PaginationComponent
                    setPage={setPage}
                    page={page}
                    pageSize={pageSize}
                    total={total}
                  />
                )}
              />
            )}
          ></TableColumnsToggler>
        ) : (
          <></>
        )}
        {activeTab === 'produced-items' ? (
          <TableColumnsToggler
            storageKey='vean-store-table-produced-items'
            columns={componentsColumns}
            render={(filterColumns) => (
              <ReactTable
                columns={filterColumns(componentsColumns)}
                data={getDataTable(false, true)}
                defaultSorted={[
                  {
                    id: 'available',
                    desc: true,
                  },
                ]}
                getTdProps={() => ({
                  style: {
                    textOverflow: 'inherit',
                    whiteSpace: 'normal',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                })}
                subRowsKey={'expand'}
                expanded={expandedItemsRows}
                onExpandedChange={(expanded, ind) => {
                  if (expandedItemsRows[ind]) {
                    setExpandedItemsRows({});
                  } else {
                    setExpandedItemsRows({ [ind]: {} });
                  }

                  setExpandedAttributesRows();
                }}
                SubComponent={(row) => (
                  <React.Fragment>
                    <h4 className='pl-3'>Товары для производства</h4>
                    <ReactTable
                      className='ml-3 mb-2 nested-table'
                      columns={filterColumns(columns(user), ['amountInItem'])}
                      data={getComponentsItemsData(row)}
                      minRows={0}
                      showPaginationBottom={false}
                      expanded={expandedAttributesRows}
                      onExpandedChange={(expanded) => {
                        setExpandedAttributesRows(expanded);
                      }}
                      subRowsKey={'expand'}
                      getTdProps={() => ({
                        style: {
                          textOverflow: 'inherit',
                          whiteSpace: 'normal',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                        },
                      })}
                      getTrProps={(state, itemRow) => {
                        if (!itemRow) return {};

                        return {
                          'data-childs': itemRow.original?.attributes?.length || 0,
                        };
                      }}
                      SubComponent={(itemRow) =>
                        itemRow.original?.attributes?.length ? (
                          <React.Fragment>
                            <h4 className='pl-3'>Варианты</h4>
                            <ReactTable
                              className='ml-3 nested-table'
                              columns={filterColumns(attributesColumns)}
                              data={itemRow.original.attributes.filter((attr) =>
                                !fullSearchFilter && isRole('administrator', user)
                                  ? attr.value !== 0
                                  : true,
                              )}
                              minRows={0}
                              showPaginationBottom={false}
                              getTdProps={() => ({
                                style: {
                                  textOverflow: 'inherit',
                                  whiteSpace: 'normal',
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                },
                              })}
                            />
                          </React.Fragment>
                        ) : null
                      }
                    />
                    {row.original?.attributes?.length ? (
                      <React.Fragment>
                        <h4 className='pl-3'>Варианты</h4>
                        <ReactTable
                          className='ml-3 nested-table'
                          columns={filterColumns(attributesColumns)}
                          data={row.original.attributes.filter((attr) =>
                            !fullSearchFilter && isRole('administrator', user)
                              ? attr.value !== 0
                              : true,
                          )}
                          minRows={0}
                          showPaginationBottom={false}
                          getTdProps={() => ({
                            style: {
                              textOverflow: 'inherit',
                              whiteSpace: 'normal',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            },
                          })}
                        />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            )}
          ></TableColumnsToggler>
        ) : null}
      </CardBody>
    </Card>
  );
});
