import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import Swal from 'sweetalert2';

import { UserContext } from '../../../../context';
import { handleAxiosErrorSwal } from 'helpers';

export const ItemRating = ({ itemId, rating, refetch }) => {
  const { api, user } = useContext(UserContext);
  const { t } = useTranslation();
  const [currentRating, setCurrentRating] = useState(rating || 0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const rateItem = useCallback(
    (rating) => {
      Swal.fire({
        title: t('swal.comment'),
        input: 'textarea',
        showCancelButton: true,
        cancelButtonText: t('buttons.cancel'),
        confirmButtonText: t('buttons.save'),
        showLoaderOnConfirm: true,
        preConfirm(value) {
          return api
            .post(`/rate-item/`, {
              comment: value,
              item: itemId,
              rating: rating,
            })
            .then((result) => {
              Swal.fire(t('swal.save'), '', 'success');
              refetch();
              setCurrentRating(rating);
            })
            .catch(handleAxiosErrorSwal);
        },
      });
    },
    [api, t],
  );

  return (
    <Row className='d-flex' style={{ width: '90px', gap: '1px' }}>
      {[1, 2, 3, 4, 5].map((star) => (
        <FontAwesomeIcon
          key={star}
          icon={faStar}
          size='md'
          style={{
            color: star <= (hoveredRating || currentRating) ? '#FFD700' : '#e4e5e9',
            cursor: 'pointer',
          }}
          onMouseEnter={() => setHoveredRating(star)}
          onMouseLeave={() => setHoveredRating(0)}
          onClick={() => rateItem(star)}
        />
      ))}
    </Row>
  );
};
