import React, { useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from './Forms/FormikComponents';
import { Form, Button, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from 'hooks/gql.hooks';
import { useTranslation } from 'react-i18next';

const TableColumnsToggler = React.memo((props) => {
  const [modalIsOpen, toggleModalState] = useToggle(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const storageData = localStorage.getItem(props.storageKey);
  const parsedStorageData = storageData ? JSON.parse(storageData) : null;
  const { t } = useTranslation();

  // Columns without `name` field will be hidden
  // const columnsWithName = props.columns.filter((el) => el.name);

  const defaultValues = useMemo(() => {
    let visibleColumnsNames = [];

    const columns = props.columns.reduce((acc, el) => {
      const name = el.name || el.Header;
      if (parsedStorageData) {
        const columnIsVisble = parsedStorageData.includes(name);

        acc[name] = columnIsVisble;

        if (columnIsVisble) {
          visibleColumnsNames.push(name);
        }
      } else {
        acc[el.name] = true;

        visibleColumnsNames.push(name);
      }

      return acc;
    }, {});

    setVisibleColumns(visibleColumnsNames);

    return columns;
  }, [storageData]);

  const filterColumns = (columns, excludedColumns = []) => {
    const fields = [...visibleColumns, ...excludedColumns];

    return columns.filter((el) => fields.includes(el.name || el.Header));
  };

  const handleFormSubmit = (values) => {
    const names = Object.keys(values).reduce((acc, key) => {
      if (values[key]) {
        acc.push(key);
      }

      return acc;
    }, []);

    setVisibleColumns(names);

    localStorage.setItem(props.storageKey, JSON.stringify(names));

    toggleModalState();
  };

  return (
    <div className='position-relative'>
      <div onClick={toggleModalState} className='toggler-icon'>
        <div className='toggler-icon__wrapper'>
          <div className='toggler-icon__icon'>
            <FontAwesomeIcon icon={faGear} size='sm' />
          </div>
          <div className='toggler-icon__text'>{t('table_toggler.settings_col')}</div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} size='lg'>
        <ModalHeader
          tag='h4'
          toggle={() => {
            toggleModalState();
          }}
        >
          {t('table_toggler.hida_show')}
        </ModalHeader>
        <ModalBody>
          <Formik initialValues={defaultValues} onSubmit={handleFormSubmit}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} autoComplete='off'>
                <Row>
                  {props.columns.map((column) => {
                    return (
                      <Col md={6} lg={3} key={column.Header}>
                        <Field
                          name={column.name || column.Header}
                          label={column?.title ?? column.Header}
                          component={Checkbox}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <Row className='justify-content-end mt-4'>
                  <Col lg={3}>
                    <Button color='primary' type='submit' style={{ width: '100%' }}>
                      {t('buttons.save')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      {props.render(filterColumns)}
    </div>
  );
});

export default TableColumnsToggler;
