import React, { useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from 'src/context.jsx';
import './FeedbackNotificationModal.scss';

const FeedbackNotificationModal = ({ notifications }) => {
  const { api } = useContext(UserContext);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  // Сохранение отложенного уведомления
  const saveDeferredNotification = (notificationId) => {
    const deferred = JSON.parse(localStorage.getItem('deferred_notifications')) || {};
    deferred[notificationId] = {
      deferredUntil: new Date().getTime() + 2 * 60 * 60 * 1000, // Текущее время + 2 часа
      deferredOnce: true, // Флаг, что уведомление уже было отложено
    };
    localStorage.setItem('deferred_notifications', JSON.stringify(deferred));
  };

  // Проверка, отложено ли уведомление
  const isNotificationDeferred = (notificationId) => {
    const deferred = JSON.parse(localStorage.getItem('deferred_notifications')) || {};
    const now = new Date().getTime();
    return deferred[notificationId]?.deferredUntil > now; // Проверяем только истечение времени
  };

  // Проверка, можно ли отложить уведомление второй раз
  const hasBeenDeferredOnce = (notificationId) => {
    const deferred = JSON.parse(localStorage.getItem('deferred_notifications')) || {};
    return !!deferred[notificationId]?.deferredOnce; // Проверяем, был ли установлен флаг
  };

  // Очистка истёкших времён отложений, но сохранение флага `deferredOnce`
  const cleanUpDeferredNotifications = () => {
    const deferred = JSON.parse(localStorage.getItem('deferred_notifications')) || {};
    const now = new Date().getTime();
    const updatedDeferred = Object.keys(deferred).reduce((result, id) => {
      if (deferred[id].deferredUntil > now || deferred[id].deferredOnce) {
        // Удаляем только истёкшие времена, но сохраняем `deferredOnce`
        result[id] = {
          ...deferred[id],
          deferredUntil: deferred[id].deferredUntil > now ? deferred[id].deferredUntil : null,
        };
      }
      return result;
    }, {});
    localStorage.setItem('deferred_notifications', JSON.stringify(updatedDeferred));
  };

  // Показать модальное окно для одного уведомления
  const showNotificationModal = async (notification) => {
    if (isNotificationDeferred(notification.id)) {
      console.log(`Notification ${notification.id} is deferred.`);
      return; // Если уведомление отложено, ничего не делаем
    }

    const formattedDate = formatTimestamp(notification.timestamp);
    const minLength = 10;
    const isWeekly = notification.notification.includes('Факт за прошлую неделю:'); // Проверяем наличие фразы для недельных уведомлений
    const frequency = isWeekly ? 'weekly' : 'daily';

    const { value: response, dismiss } = await Swal.fire({
      title:
        '<div style="font-size: 16px; line-height: 1.2;">' + notification.notification + '</div>',
      html: `
        <textarea id="feedback-text" class="swal2-textarea" placeholder="Enter your response"></textarea>
      `,
      icon: 'info',
      confirmButtonText: 'Отправить',
      showCancelButton: !hasBeenDeferredOnce(notification.id), // Показываем кнопку "Defer" только если уведомление не было отложено
      cancelButtonText: 'Отложить на 2 часа для выяснения причин',
      allowOutsideClick: false,
      customClass: {
        popup: 'custom-modal',
      },
      preConfirm: () => {
        const feedbackText = document.getElementById('feedback-text').value;
        if (!feedbackText) {
          Swal.showValidationMessage('Response is required');
        }
        if (!feedbackText || feedbackText.trim().length < minLength) {
          Swal.showValidationMessage(`Please enter at least ${minLength} characters.`);
        }
        return feedbackText;
      },
    });

    if (dismiss === Swal.DismissReason.cancel) {
      // Если пользователь выбрал "Отложить"
      saveDeferredNotification(notification.id); // Сохраняем отложение для конкретного уведомления
      return 'deferred';
    }

    if (response) {
      try {
        await api.post('/notification_feedback/', {
          notification: notification.id,
          response: response,
          frequency: frequency,
        });
        console.log(`Feedback for notification ${notification.id} submitted successfully.`);
      } catch (error) {
        console.error(`Error submitting feedback for notification ${notification.id}:`, error);
        Swal.fire('Error', 'Failed to submit feedback. Please try again.', 'error');
      }
    }
  };

  // Обработать уведомления по очереди
  const handleNotifications = async (notificationsToProcess) => {
    for (const notification of notificationsToProcess) {
      const result = await showNotificationModal(notification);
      if (result === 'deferred') {
        continue; // Продолжаем обработку следующих уведомлений
      }
    }
  };

  useEffect(() => {
    cleanUpDeferredNotifications(); // Удаляем истёкшие отложенные уведомления

    if (notifications.length) {
      handleNotifications(notifications);
    }
  }, [notifications]);

  return null;
};

export default FeedbackNotificationModal;
